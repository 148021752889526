import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationModalComponent } from '../translation-modal/translation-modal.component';

@Component({
  selector: 'translated-input',
  templateUrl: './translated-input.component.html',
  styleUrls: ['./translated-input.component.scss']
})
export class TranslatedInputComponent implements OnInit {
  @Input() translateKey: boolean = true;
  @Input() label!: string;
  @Input() model: any = {};
  @Output() modelChange = new EventEmitter<any>();


  @ViewChild('translationModal') translationModal: TranslationModalComponent | undefined;

  @Output() translated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }

  onTranslateButtonClicked(model: any) {
    this.translationModal!.data = model;
    this.translationModal!.toggle();
  }

  onTranslationsConfirmed() {
    this.translated.emit(this.model);
    this.translationModal!.toggle();
  }

}
