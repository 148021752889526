import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AppTranslationsService } from './app-translations.service';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {

  public loadingPanelVisible: boolean = false;
  public message: string = 'Loading';

  static STYLE_NONE: 'none' | 'success' | 'warning' | 'error' | 'info' = 'none';
  static STYLE_SUCCESS: 'none' | 'success' | 'warning' | 'error' | 'info' = 'success';
  static STYLE_WARNING: 'none' | 'success' | 'warning' | 'error' | 'info' = 'warning';
  static STYLE_ERROR: 'none' | 'success' | 'warning' | 'error' | 'info' = 'error';
  static STYLE_INFO: 'none' | 'success' | 'warning' | 'error' | 'info' = 'info';

  constructor(private notificationService: NotificationService, private translationService: TranslateService) {

  }

  public toggleLoader(message?: string | undefined) {
    this.message = message ? message : 'Loading';
    this.loadingPanelVisible = !this.loadingPanelVisible;
  }

  public showNotification(message: string, style: 'none' | 'success' | 'warning' | 'error' | 'info' = AppNotificationService.STYLE_INFO) {
    this.notificationService.show({
      content: this.translationService.instant(message),
      hideAfter: style == 'error' ? 10000 : 500,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 1000 },
      type: { style: style, icon: true },
    });
  }
}