import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AppNotificationService } from 'src/app/app-notification.service';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { PersonService } from '../../people/person.service';
import { PersonsRelationTypeService } from '../../people/persons-relation-types.service';
import { PersonsRelationsService } from '../../people/persons-relations.service';
import { TitlesService } from '../../people/titles.service';

@Component({
  selector: 'app-relation-form',
  templateUrl: './relation-form.component.html',
  styleUrls: ['./relation-form.component.scss']
})
export class RelationFormComponent implements OnInit {
  @Input() public relations: any[] = [];
  @Output() public relationsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() public onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addModal') addModal: ModalComponent = new ModalComponent();

  public relationTypesHelper: KendoComponentHelper = new KendoComponentHelper();
  public titlesHelper: KendoComponentHelper = new KendoComponentHelper();

  public actions: any[] = [
    {
      code: 'add-relation',
      text: 'Add relation',
      icon: 'user',
      click: () => this.onAddRelationButtonClicked(),
    },
  ];

  constructor(
    private personsRelationTypeService: PersonsRelationTypeService,
    private titlesService: TitlesService,
    private personsRelationsService: PersonsRelationsService,
    private notificationService: AppNotificationService,
    private personService: PersonService,
  ) { }

  ngOnInit(): void {
    forkJoin({
      types: this.personsRelationTypeService.all(),
      titles: this.titlesService.all(),
    }).subscribe((responses: any) => {
      this.relationTypesHelper.datas = this.relationTypesHelper.filteredDatas = responses.types.data;
      this.titlesHelper.datas = this.titlesHelper.filteredDatas = responses.titles.data;
    })
  }

  // Search
  // ------
  public emails: Array<any> = [];
  public isSearching: boolean = false;
  public hasSearchForEmail: boolean = false;
  public personAlreadyExist: boolean = false;
  public shouldShowDropDown: boolean = false;

  public async onSearchEmailClicked(): Promise<void> {
    this.isSearching = true;
    this.personAlreadyExist = false;
    this.hasSearchForEmail = false;
    this.shouldShowDropDown = false;

    const result: any = await this.personService.all({ filter: { filters: [{ field: 'primary_email', operator: 'contains', value: this.addModal.data.primary_email, ignoreCase: true }], logic: 'and' } });

    if (result.data.length === 0) {
      this.hasSearchForEmail = true;
    } else if (result.data.length > 1) {
      this.emails = result.data;
      this.shouldShowDropDown = true;
    } else if (result.data.length === 1) {
      this.onPersonFound(result.data[0]);
    }

    this.isSearching = false;
  }

  public onPersonFound(person: any) {
    this.addModal.data.id = person.id;
    this.addModal.data.firstname = person.firstname;
    this.addModal.data.lastname = person.lastname;
    this.addModal.data.primary_email = person.primary_email;
    this.addModal.data.title = person.person_title;

    this.personAlreadyExist = true;
    this.hasSearchForEmail = true;
    this.shouldShowDropDown = false;
  }

  public onKeyDown(pressedKey: any) {
    if (pressedKey.key === "Enter") {
      this.onSearchEmailClicked();
    }
  }

  // Create
  // ------
  public onAddRelationButtonClicked() {
    this.addModal.data = {
      firstname: '',
      lastname: '',
      primary_email: '',
      id: null,
      relation_type: { id: 1 }
    };
    this.hasSearchForEmail = false;
    this.shouldShowDropDown = false;
    this.addModal.toggle();
  }

  public async onConfirmAdd(): Promise<void> {
    this.addModal.data.title_id = this.addModal.data.title.id;
    if (!this.personAlreadyExist && !await this.personService.canCreatePerson(this.addModal.data, true)) return;

    this.addModal.toggle();

    if (this.onCreate.observed) return this.onCreate.emit(this.addModal.data);

    this.notificationService.showNotification('Record Created', 'success');
  }

  // delete
  // ------
  public async onConfirmDelete(relation: any): Promise<void> {
    await this.personsRelationsService.deleteAssoc({
      person_id: relation.person_id,
      relation_id: relation.relation_id,
      relation_type_id: relation.relation_type_id,
    });

    if (this.onDelete.observed) return this.onDelete.emit(relation);

    this.notificationService.showNotification('Record Deleted', 'success');
  }
}
