<app-toolbar [actions]="actions"></app-toolbar>
<div class="row">
    <ng-container *ngFor="let relation of relations; let i=index;">
        <div class="col-sm-4 my-2">
            <app-relation-card [(relation)]="relations[i]" (delete)="onConfirmDelete($event)"></app-relation-card>
        </div>
    </ng-container>
</div>

<app-modal #addModal title="Add relation" (confirm)="onConfirmAdd()">
    <ng-template modalContent>
        <kendo-label text="{{'Primary email' | translate}}"></kendo-label>
        <div class="d-flex align-items-center">
            <kendo-textbox name="primaryEmail" [(ngModel)]="addModal.data.primary_email" (keydown)="onKeyDown($event)"></kendo-textbox>
            <i *ngIf="!isSearching" class="fas fa-search ms-2 p-2" role="button" (click)="onSearchEmailClicked()"></i>
            <i *ngIf="isSearching" class="fa-solid fa-spinner fa-spin-pulse ms-2 p-2"></i>
        </div>

        <div class="mt-3" *ngIf="shouldShowDropDown">
            <kendo-label text="{{'Sélectionner l\'adresse email' | translate}}"></kendo-label>
            <kendo-dropdownlist name="primaryEmails" (valueChange)="onPersonFound($event)" [data]="emails" textField="primary_email" valueField="primary_email"></kendo-dropdownlist>
        </div>

        <div class="mt-3" *ngIf="hasSearchForEmail">
            <kendo-label text="{{'Title' | translate}}"></kendo-label>
            <kendo-dropdownlist name="title" [(ngModel)]="addModal.data.title" [data]="titlesHelper.datas" textField="displayName" valueField="id" [disabled]="personAlreadyExist"></kendo-dropdownlist>
        </div>

        <div class="mt-3" *ngIf="hasSearchForEmail">
            <kendo-label text="{{'Lastname' | translate}}"></kendo-label>
            <kendo-textbox name="lastname" [(ngModel)]="addModal.data.lastname" [disabled]="personAlreadyExist"></kendo-textbox>
        </div>

        <div class="mt-3" *ngIf="hasSearchForEmail">
            <kendo-label text="{{'Firstname' | translate}}"></kendo-label>
            <kendo-textbox name="firstname" [(ngModel)]="addModal.data.firstname" [disabled]="personAlreadyExist"></kendo-textbox>
        </div>

        <!-- <ng-container *ngIf="hasSearchForEmail">
            <kendo-label text="{{'Relation type' | translate}}"></kendo-label>
            <kendo-dropdownlist [(ngModel)]="addModal.data.relation_type" [data]="relationTypesHelper.filteredDatas" textField="displayName.fr" valueField="id"></kendo-dropdownlist>
        </ng-container> -->
    </ng-template>
</app-modal>