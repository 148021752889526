import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { lastValueFrom, of } from 'rxjs';
import { MembersService } from './members.service';

@Injectable({
  providedIn: 'root'
})
export class MemberResolver  {
  public element_id: string | number | undefined;

  constructor(
    private memberService: MembersService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.element_id = route.params.id;
    return this.element_id != 'new' ? this.memberService.find(this.element_id) : lastValueFrom(of({ data: { isMember: true } }));
  }
}
