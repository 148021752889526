import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { CommitteeDetailsComponent } from './admin/committees/committee-details/committee-details.component';
import { CommitteesComponent } from './admin/committees/committees.component';
import { MembersComponent } from './admin/members/members.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { TranslationsComponent } from './admin/settings/translations/translations.component';
import { LoginComponent } from './auth/login/login.component';
import { PersonDetailsComponent } from './admin/people/person-details/person-details.component';
import { MemberResolver } from './admin/members/member.resolver';
import { CommitteeResolver } from './admin/committees/committee.resolver';
import { PersonResolver } from './admin/people/person.resolver';
import { AdminResolver } from './admin/admin.resolver';
import { PeopleComponent } from './admin/people/people.component';
import { AssistantsComponent } from './admin/assistants/assistants.component';
import { AppointmentResolver } from './admin/appointments/appointment.resolver';
import { AppointmentsComponent } from './admin/appointments/appointments.component';
import { AppointmentDetailsComponent } from './admin/appointments/appointment-details/appointment-details.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [MsalGuard],
    resolve: { admin: AdminResolver },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'members' },
      // { path: 'home', component: HomeComponent },

      { path: 'members', component: MembersComponent },
      { path: 'members/:id', component: PersonDetailsComponent, resolve: { element: MemberResolver } },

      { path: 'communications', component: PeopleComponent },
      { path: 'communications/:id', component: PersonDetailsComponent, resolve: { element: PersonResolver } },
      
      { path: 'assistants', component: AssistantsComponent },
      { path: 'assistants/:id', component: PersonDetailsComponent, resolve: { element: PersonResolver } },
      
      { path: 'committees', component: CommitteesComponent },
      { path: 'committees/:id', component: CommitteeDetailsComponent, resolve: { element: CommitteeResolver } },
      
      { path: 'sections', pathMatch: 'full', redirectTo: 'committees' },
      { path: 'sections/:id', component: CommitteeDetailsComponent, resolve: { element: CommitteeResolver } },
      
      { path: 'appointments', component: AppointmentsComponent },
      { path: 'appointments/:id', component: AppointmentDetailsComponent, resolve: { element: AppointmentResolver } },

      // { path: 'companies', component: CompaniesComponent },
      // { path: 'companies/:id', component: CompanyDetailsComponent },
      // { path: 'functions', component: CompanyRolesComponent },
      // { path: 'functions/:id', component: CompanyRoleDetailsComponent },
      // { path: 'tenures', component: TenuresComponent },
      // { path: 'tenures/:id', component: TenureDetailsComponent },

      { path: 'settings', component: SettingsComponent },
      { path: 'settings/translations', component: TranslationsComponent },

      { path: '**', redirectTo: 'members' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !environment.isIframe ? 'enabledBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
