<app-modal
    #committeeAppointmentsStatisticsModal
    width="500px"
    title="Appointments statistics"
    [confirmText]="'Export'"
    (confirm)="onConfirmModal()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Start at' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="committeeAppointmentsStatisticsModal.data.startDate"></kendo-datepicker>
        <br>
        <br>
        <kendo-label text="{{'End at' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="committeeAppointmentsStatisticsModal.data.endDate"></kendo-datepicker>
    </ng-template>
</app-modal>