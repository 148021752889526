<app-grid #grid [columns]="columns" [service]="translationService" [searchable]="true" [exportable]="true" align="end" [actions]="translationsActions" (edit)="onEditTranslationButtonClicked($event)"></app-grid>

<app-modal #addTranslationModal title="Add a translation" (confirm)="onConfirmAddTranslationButtonClicked()">
    <ng-template modalContent>
        <div class="row">
            <kendo-label class="col-sm-4" text="{{'Key' | translate}}"></kendo-label>
            <input kendoTextBox class="col-sm-8" name="key" [(ngModel)]="addTranslationModal.data.key">
        </div>
        <translated-input class="col-sm-8" label="Value" [(model)]="addTranslationModal.data.value"></translated-input>
    </ng-template>
</app-modal>

<app-translation-modal #editTranslationModal (confirm)="onEditTranslationsConfirmed()"></app-translation-modal>
<app-modal-confirm #deleteTranslationModal (confirm)="onConfirmDeleteTranslationButtonClicked()"></app-modal-confirm>