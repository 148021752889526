<kendo-card class="w-100 rounded-0" *ngIf="element">
  <kendo-card-body>
    <div class="d-flex py-2">
      <arrow-stepper-item class="w-100 flex-grow" *ngFor="let item of statuses" [item]="item">
        <div class="h-100 d-flex flex-column justify-content-center align-items-center">
          <span class="fw-bold text-white text-center">
            {{ item.data.displayName?.fr }}
          </span>

          <span class="fw-bold text-white text-center">
            {{ item.data.Date | date }}
          </span>
        </div>
      </arrow-stepper-item>
    </div>

    <div class="k-form px-5">
      <div class="row">
        <div class="col-6">
          <div>
            <kendo-label text="{{ 'Subject' | translate }}"></kendo-label>
            <kendo-textbox [(ngModel)]="element.subject" [disabled]="true"></kendo-textbox>
          </div>
          <div class="row my-3">
            <div class="col-4">
              <kendo-label text="{{ 'Promoter' | translate }}"></kendo-label>
              <kendo-textbox [(ngModel)]="element.promoter" [disabled]="true"></kendo-textbox>
            </div>
            <div class="col-4" *ngIf="element.startDate">
              <kendo-label text="{{ 'Start at' | translate }}"></kendo-label>
              <kendo-datepicker [(value)]="element.startDate" format="dd/MM/yyyy HH:mm"
                [disabled]="true"></kendo-datepicker>
            </div>
            <div class="col-4" *ngIf="element.endDate">
              <kendo-label text="{{ 'End at' | translate }}"></kendo-label>
              <kendo-datepicker [(value)]="element.endDate" format="dd/MM/yyyy HH:mm"
                [disabled]="true"></kendo-datepicker>
            </div>
            <div class="col-4">
              <!-- <kendo-label text="{{ 'Duration' | translate }}"></kendo-label>
              <kendo-textbox
                [ngModel]="5"
                [disabled]="true"
              ></kendo-textbox> -->
              <!-- <kendo-textbox
                [(ngModel)]="duration  5"
                [disabled]="true"
              ></kendo-textbox> -->
            </div>
          </div>
          <div>
            <kendo-label text="{{ 'Committees' | translate }} / {{
                'Sections' | translate
              }}"></kendo-label>
            <kendo-multiselect [data]="committees" [(ngModel)]="selectedCommittees" textField="code"
              valueField="id"></kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <kendo-tabstrip [keepTabContent]="true">
        <kendo-tabstrip-tab title="{{ 'Attendees' | translate }}" [selected]="true">
          <ng-template kendoTabContent>
            <app-grid #attendeesGrid [columns]="columns" [data]="element.appointment_attendees" [exportable]="true"
              [searchable]="false" [deletable]="false" [editable]="false" [filterable]="false" [sortable]="false"
              [pageable]="false" localStorageKey="state-grid-appointment-attendees"
              (onBooleanClick)="onPresenceChange($event)" (edit)="onEditAppointmentAttendee($event)"
              (export)="onAttendeeExport()"></app-grid>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab *ngIf="element.isTeams" title="{{ 'Teams' | translate }}">
          <ng-template kendoTabContent>
            <div class="d-flex align-items-end">
              <div class="">
                <kendo-label text="{{ 'Appointment link' | translate }}"></kendo-label>
                <kendo-textbox [ngModel]="element.teamsUrl" [disabled]="true"></kendo-textbox>
              </div>
              <div class="ms-4">
                <button class="btn btn-danger" (click)="copyTeamsUrl(element.teamsUrl)">
                  <i class="fa-solid fa-copy me-2"></i>
                  {{ "Copy link" | translate }}
                </button>
              </div>
              <div class="ms-4">
                <button class="btn btn-danger" (click)="onJoinTeamsUrl(element.teamsUrl)">
                  <i class="fa-solid fa-right-to-bracket me-2"></i>
                  {{ "Join" | translate }}
                </button>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </kendo-card-body>
</kendo-card>

<app-modal-confirm #appointmentDeleteModal content="{{'Are you sure to delete' | translate}}"
  (confirm)="onConfirmDelete()"></app-modal-confirm>

<app-modal #addAttendeeModal title="Add attendee" (confirm)="onConfirmAddAttendeeBtn()">
  <ng-template modalContent>
    <kendo-label text="{{'Primary email' | translate}}"></kendo-label>
    <div class="d-flex align-items-center">
      <kendo-textbox name="primaryEmail" [(ngModel)]="addAttendeeModal.data.primary_email"
        (keydown)="onKeyDown($event)"></kendo-textbox>
      <i *ngIf="!isSearching" class="fas fa-search ms-2 p-2" role="button" (click)="onSearchEmailClicked()"></i>
      <i *ngIf="isSearching" class="fa-solid fa-spinner fa-spin-pulse ms-2 p-2"></i>
    </div>

    <div class="mt-3" *ngIf="shouldShowDropDown">
      <kendo-label text="{{'Sélectionner l\'adresse email' | translate}}"></kendo-label>
      <kendo-dropdownlist name="primaryEmails" (valueChange)="onPersonFound($event)" [data]="emails"
        textField="primary_email" valueField="primary_email"></kendo-dropdownlist>
    </div>

    <div class="mt-3" *ngIf="hasSearchForEmail">
      <kendo-label text="{{'Title' | translate}}"></kendo-label>
      <kendo-dropdownlist name="title" [(ngModel)]="addAttendeeModal.data.title" [data]="titlesHelper.datas"
        textField="displayName" valueField="id" [disabled]="personAlreadyExist"></kendo-dropdownlist>
    </div>

    <div class="mt-3" *ngIf="hasSearchForEmail">
      <kendo-label text="{{'Lastname' | translate}}"></kendo-label>
      <kendo-textbox name="lastname" [(ngModel)]="addAttendeeModal.data.lastname"
        [disabled]="personAlreadyExist"></kendo-textbox>
    </div>

    <div class="mt-3" *ngIf="hasSearchForEmail">
      <kendo-label text="{{'Firstname' | translate}}"></kendo-label>
      <kendo-textbox name="firstname" [(ngModel)]="addAttendeeModal.data.firstname"
        [disabled]="personAlreadyExist"></kendo-textbox>
    </div>

    <!-- <ng-container *ngIf="hasSearchForEmail">
          <kendo-label text="{{'Relation type' | translate}}"></kendo-label>
          <kendo-dropdownlist [(ngModel)]="addAttendeerModal.data.relation_type" [data]="relationTypesHelper.filteredDatas" textField="displayName.fr" valueField="id"></kendo-dropdownlist>
      </ng-container> -->
  </ng-template>
</app-modal>

<app-modal #editAppointmentAttendeerModal title="Edit response status"
  (confirm)="onConfirmEditAppointmentAttendeeBtn()">
  <ng-template modalContent>
    <div>
      <kendo-label text="{{'Lastname' | translate}}"></kendo-label>
      <kendo-textbox name="lastname" [ngModel]="editAppointmentAttendeerModal.data.person.lastname"
        [disabled]="true"></kendo-textbox>
    </div>

    <div class="mt-3">
      <kendo-label text="{{'Firstname' | translate}}"></kendo-label>
      <kendo-textbox name="firstname" [ngModel]="editAppointmentAttendeerModal.data.person.firstname"
        [disabled]="true"></kendo-textbox>
    </div>

    <div class="mt-3">
      <kendo-label text="{{'Réponse' | translate}}"></kendo-label>
      <kendo-dropdownlist [(ngModel)]="editAppointmentAttendeerModal.data.appointment_attendee_response_id"
        (ngModelChange)="editAppointmentAttendeerModal.data.appointment_attendee_response_id_is_edited = true"
        [data]="appointmentAttendeeResponsesHelper.datas" [valuePrimitive]="true" textField="displayName.fr"
        valueField="id"
        [disabled]="(editAppointmentAttendeerModal.data.appointment_attendee_response_id === 3 || editAppointmentAttendeerModal.data.appointment_attendee_response_id === 6) && !editAppointmentAttendeerModal.data.appointment_attendee_response_id_is_edited"></kendo-dropdownlist>
    </div>
  </ng-template>
</app-modal>

<app-modal #synchroniseSelectModal title="Synchronize" width="300px"
  (confirm)="onConfirmSynchronizeModal(synchroniseSelectModal.data)">
  <ng-template modalContent>
      <div class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Statut'}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncStatus"></kendo-switch>
      </div>


      <div class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Generals data' | translate}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncGeneralData"></kendo-switch>
      </div>


      <div class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Committees' | translate}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncCommittees"></kendo-switch>
      </div>


      <div class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Appointment type' | translate}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncAppointmentType"></kendo-switch>
      </div>


      <div class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Attendees' | translate}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncAttendees"></kendo-switch>
      </div>


      <div *ngIf="element?.isTeams" class="d-flex justify-content-between my-2">
        <kendo-label text="{{'Attendance Teams' | translate}}"></kendo-label>
        <kendo-switch [(ngModel)]="synchroniseSelectModal.data.syncAttendance"></kendo-switch>
      </div>
  </ng-template>
</app-modal>