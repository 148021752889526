import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AssistantsService extends AppApiService {
  public url: string | null = 'cesew/assistants';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService
  ) {
    super(http, notificationService);
  }

  public getMembers(assistant_id: number) {
    return this.get(null, this.url + '/members?id=' + assistant_id);
  }
}
