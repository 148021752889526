<kendo-dialog
  [title]="'Change email' | translate"
  [minWidth]="250"
  [width]="450"
  *ngIf="opened"
  (close)="opened = false"
>
  <form
    class="k-form k-form-sm"
    [formGroup]="form"
  >
    <fieldset class="k-form-fieldset">

      <kendo-formfield>
        <kendo-label
          [for]="newEmail"
          [text]="'New Email'|translate"
        ></kendo-label>
        <kendo-textbox
          formControlName="newEmail"
          [email]="true"
          #newEmail
          required
        >
        </kendo-textbox>
        <kendo-formerror *ngIf="form.controls.newEmail.errors?.required">{{ "The new email address is required." | translate}}</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.newEmail.errors?.email">{{ "The new email address is not a valid email address." | translate}}</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label
          [for]="sendInviteMail"
          [text]="'Send invitation mail'|translate"
        ></kendo-label>
        <kendo-switch
          formControlName="sendInviteMail"
          #sendInviteMail
        >
        </kendo-switch>
      </kendo-formfield>
    </fieldset>
  </form>
  <kendo-dialog-actions>
    <button
      kendoButton
      (click)="cancel()"
    >
      {{"Cancel" | translate}}
    </button>
    <button
      kendoButton
      (click)="confirm()"
      themeColor="primary"
      [disabled]="form.invalid || changeMailRequestSent"
    >
      {{"Validate" | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<app-modal-confirm
  #confirmModal
  [content]="'Are you sure you want to change the email address of this person ?' | translate"
  (confirm)="send()"
>
</app-modal-confirm>
