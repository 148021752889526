import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationsService } from '../app-translations.service';
import { AuthenticationService } from '../auth/authentication.service';
import { ToolbarService } from '../ui/toolbar/toolbar.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  isMobile: boolean = false;

  public userMenuItems: any[] = [];

  constructor(
    public authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public translationService: AppTranslationsService,
    public toolbarService: ToolbarService
  ) { }

  ngOnInit(): void {
    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) { this.isMobile = true; }
    else { this.isMobile = false; }

    this.userMenuItems = [{
      id: 'logout', text: this.translateService.instant('Logout'),
    }];
  }

  public drawerMenuItems: Array<any> = [
    // { text: 'Home', icon: 'home', route: '/admin/home' },

    { text: 'Members', icon: 'users', route: '/admin/members' },
    { text: 'Communications', icon: 'user-group', route: '/admin/communications' },
    { text: 'Assistants', icon: 'people-group', route: '/admin/assistants' },
    { text: 'Committees', icon: 'folder', route: '/admin/committees' },
    { text: 'Appointments', icon: 'calendar-check', route: '/admin/appointments' },

    // { text: 'Companies', icon: 'users', route: '/admin/companies' },
    // { text: 'Tenures', icon: 'users', route: '/admin/tenures' },
    // { text: 'Functions', icon: 'users', route: '/admin/functions' },

    // { text: 'Settings', icon: 'cogs', route: '/admin/settings' }
  ];

  public onProfileButtonItemClicked(e: any) {
    if (e.id == 'logout') {
      this.authenticationService.logout();
    }
  }
}
