export class Country {
  public id?: number;
  public alpha2Code!: string;
  public alpha3Code!: string;
  public displayName!: {
    en: string,
    fr: string
  }

  static fromResponse(object: Object) : Country{
    let country = Object.assign(new Country(),object);
    return country;
  }
}
