<div
  class="w-100 d-flex justify-content-between py-3 align-items-center border-bottom mb-2"
>
  <div class="d-flex justify-content-start align-items-center">
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="!action.atEnd"
        [hasPermission]="['members.update']"
        [toRemove]="true"
        class="btn btn-{{ action.btnStyle ?? 'outline' }} {{ action.btnClass }} me-2"
        (click)="action?.click($event)"
      >
        <i
          *ngIf="action.icon"
          class="fas fa-{{ action.icon }} {{ action.iconClass }}"
          [style]="action.iconStyle"
        ></i>
        <img
          *ngIf="action.image"
          class="img-fluid {{ action.imageClass }}"
          [src]="action.image"
          [style]="action.imageStyle"
        />
        <span
          *ngIf="action.text"
          [style]="action.textStyle"
          class="{{ action.textClass }} ms-2"
        >
          {{ action.text | translate }}
        </span>
      </button>
    </ng-container>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="action.atEnd"
        [hasPermission]="['members.update']"
        [toRemove]="true"
        class="btn btn-{{ action.btnStyle ?? 'outline' }} {{ action.btnClass }} ms-2"
        (click)="action?.click($event)"
      >
        <i
          *ngIf="action.icon"
          class="fa-solid fa-{{ action.icon }} {{ action.iconClass }}"
          [style]="action.iconStyle"
        ></i>
        <img
          *ngIf="action.image"
          class="img-fluid {{ action.imageClass }}"
          [src]="action.image"
          [style]="action.imageStyle"
        />
        <span
          *ngIf="action.text"
          [style]="action.textStyle"
          class="{{ action.textClass }} ms-2"
        >
          {{ action.text | translate }}
        </span>
      </button>
    </ng-container>

    <kendo-textbox
      *ngIf="searchable"
      (afterValueChanged)="onSearchFieldChanged($event)"
      placeholder="{{ 'Search' | translate }}..."
      name="searchValue"
      [(ngModel)]="searchValue"
      [clearButton]="true"
    ></kendo-textbox>
    <button
      *ngIf="filterable"
      class="ms-1 btn btn-link"
      (click)="onFilterButtonClicked($event)"
    >
      <i class="fas fa-filter"></i>
    </button>
    <button
      *ngIf="exportable"
      class="ms-1 btn btn-link text-success"
      (click)="onExportButtonClicked()"
    >
      <i
        class="fas fa-{{
          isExporting ? 'spinner fa-spin-pulse' : 'file-excel'
        }}"
      ></i>
    </button>
  </div>
</div>
