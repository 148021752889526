<kendo-drawer-container *ngIf="authenticationService.user" class="bg-light">
  <div class="vh-100 bg-primary d-flex flex-column sticky-top">
    <kendo-drawer #drawer class="vh-100 bg-primary flex-fill" mode="push" [mini]="false" [expanded]="!isMobile" [autoCollapse]="true">
      <ng-template kendoDrawerTemplate>
        <div class="vh-100 d-flex flex-column">
          <div class="flex-grow-1">
            <nav class="d-flex flex-column">
              <a routerLink="/admin/home" class="bg-white p-3 text-center">
                <img src="/assets/logo.png" style="width: 200px" />
              </a>
              <a
                *ngFor="let item of drawerMenuItems"
                routerLink="{{ item.route }}"
                class="h6 text-white px-4 py-3 d-flex align-items-center m-0 border-bottom border-white text-decoration-none"
                role="button"
              >
                <i class="fas fa-{{ item.icon }}"></i>
                <span class="k-item-text ps-2">{{ item.text | translate }}</span>
              </a>
            </nav>
          </div>
          <p class="text-white m-1" style="font-size: 0.9rem; user-select: none;">1.12.3.3</p>
        </div>
      </ng-template>
    </kendo-drawer>
  </div>

  <kendo-drawer-content>
    <kendo-appbar position="top" class="bg-primary p-4 w-100">
      <kendo-appbar-section
        ><button class="btn btn-link text-white" (click)="drawer.toggle()">
          <i class="fas fa-bars"></i></button
      ></kendo-appbar-section>

      <kendo-appbar-spacer></kendo-appbar-spacer>

      <kendo-appbar-section>
        <kendo-avatar
          imageSrc="assets/user.svg"
          shape="circle"
          width="40px"
          height="40px"
        ></kendo-avatar>
        <kendo-dropdownbutton
          [data]="userMenuItems"
          themeColor="primary"
          (itemClick)="onProfileButtonItemClicked($event)"
          class="mx-2"
        >
          {{ authenticationService.user?.username }}
          <i class="fas fa-chevron-down"></i>
        </kendo-dropdownbutton>

      </kendo-appbar-section>
    </kendo-appbar>

    <div class="container-fluid p-3">
      <app-breadcrumbs #breadcrumb></app-breadcrumbs>
      <app-toolbar
        *ngIf="toolbarService.actions.length > 0"
        [actions]="toolbarService.actions"
        [searchable]="toolbarService.searchable"
        [filterable]="toolbarService.filterable"
        [exportable]="toolbarService.exportable"
        (search)="toolbarService.search($event)"
        (filter)="toolbarService.filter($event)"
        (export)="toolbarService.export($event)"
      ></app-toolbar>
      <router-outlet></router-outlet>
    </div>
  </kendo-drawer-content>
</kendo-drawer-container>
