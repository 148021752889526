import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CommitteesMembersService extends AppApiService {
  public loading: boolean = false;
  public url: string | null = 'cesew/committeesMembers';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(http, notificationService);
  }

  create(element: any): Promise<Object | undefined> {
    return this.post(element);
  }

  update(element: any): Promise<Object | undefined> {
    return this.put(element);
  }
}
