export const environment = {
  production: true,

  frontendBaseURL: "https://bdd.cesewallonie.be",
  apiUrl: "https://api.bdd.cesewallonie.be",
  backendBaseUrl: "https://api.bdd.cesewallonie.be/",


  // Microsoft AAD variables
  // -----------------------
  mGraphEndoint: 'https://graph.microsoft.com/v1.0',
  mClientID: '06b9fc82-5e2f-4768-8135-3e96930b2fdf',
  mAuthority: 'https://login.microsoftonline.com/organizations',
  isIE: window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1,
  isIframe: window !== window.parent && !window.opener
};