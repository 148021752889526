<li>
    <input class="k-textbox k-input k-rounded-md" (input)="onInput($event)" />
</li>
<ul id="multiCheck">
    <li
        *ngFor="let item of filteredItems; let i = index;"
        (click)="onSelectionChange(item)"
        [ngClass]="{'k-selected': isItemSelected(item)}"
        class="d-flex align-items-center"
    >
        <input
            type="checkbox"
            id="chk-{{item.id}}"
            [checked]="isItemSelected(item)"
        />
        <kendo-label
            class="k-multiselect-checkbox k-checkbox-label"
            for="chk-{{item.id}}"
        >
            {{ item.displayName.fr }}
        </kendo-label>
    </li>
</ul>