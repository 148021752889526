import { Committee } from "../../committees/committee.modele";
import { Appointment } from "../appointment";

export class AppointmentsCommittees {

    public id: number | string | undefined;
    public committee?: Committee;
    public committee_id?: number | string | undefined;
    public appointment?: Appointment;
    public appointment_id?: number | string | undefined;

    constructor(object: Partial<AppointmentsCommittees>) {
        Object.assign(this, object);
    }
}
