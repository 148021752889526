<kendo-card class="w-100 rounded-0">
    <kendo-card-body>
        <div class="k-form px-5">
            <app-person-form
                [(person)]="element"
                [(member)]="member"
            ></app-person-form>
        </div>
        <div
            class="mt-5"
            *ngIf="element.id"
        >
            <kendo-tabstrip [keepTabContent]="true">
                <!-- <kendo-tabstrip-tab title="{{'Contacts' | translate}}">
                    <ng-template kendoTabContent>
                        <app-contact-form [(contacts)]="element.persons_contacts" (onCreate)="onContactCreate($event)" (onUpdate)="onContactUpdate()" (onDelete)="onContactDelete($event)"></app-contact-form>
                    </ng-template>
                </kendo-tabstrip-tab> -->
                <kendo-tabstrip-tab
                    title="{{'Adresses' | translate}}"
                    [selected]="true"
                >
                    <ng-template kendoTabContent>
                        <app-address-form
                            [(addresses)]="element.persons_addresses"
                            (onCreate)="onAddressCreate($event)"
                            (onDelete)="onAddressDelete($event)"
                        ></app-address-form>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab
                    title="{{'Relations' | translate}}"
                    *ngIf="member"
                >
                    <ng-template kendoTabContent>
                        <app-relation-form
                            [(relations)]="element.persons_relations"
                            (onCreate)="onRelationCreate($event)"
                            (onDelete)="onRelationDelete()"
                        ></app-relation-form>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab
                    *ngIf="member"
                    title="{{'Committees' | translate}} & {{'Sections' | translate}}"
                >
                    <ng-template kendoTabContent>

                        <app-toolbar [actions]="committeesActions"></app-toolbar>

                        <kendo-grid
                            #grid="kendoGrid"
                            [loading]="loading"
                            [data]="data"
                            [sortable]="true"
                            [filterable]="false"
                        >
                            <kendo-grid-column
                                title=""
                                [width]="40"
                                headerClass="text-center"
                            >
                                <ng-template
                                    kendoGridCellTemplate
                                    let-dataItem
                                >
                                    <span
                                        class="d-inline-block rounded-circle bg-{{dataItem.committee.microsoft_id ? 'success': 'danger'}}"
                                        style="width: 10px; height: 10px;"
                                    ></span>
                                </ng-template>
                            </kendo-grid-column>
                            <!-- <kendo-grid #grid="kendoGrid" [loading]="loading" [data]="dataResult" [selectable]="selectable" [(selectedKeys)]="selectedElements" [kendoGridSelectBy]="selectedBy" [pageSize]="state.take" [skip]="state.skip" [pageable]="pageable" [sortable]="sortable" [sort]="state.sort" [filter]="state.filter" [filterable]="filterable ? 'menu' : false" (dataStateChange)="dataStateChange($event)" (selectedKeysChange)="selectedElementsChange.emit($event)" (cellClick)="onCellClick($event)" (dblclick)="onDblClick($event)"> -->
                            <kendo-grid-column
                                *ngFor="let col of columns"
                                [field]="col.field"
                                [filter]="col.filter"
                                title=""
                            >
                                <ng-template
                                    kendoGridFilterMenuTemplate
                                    let-filter
                                    let-column="column"
                                    let-filterService="filterService"
                                >
                                    <kendo-grid-string-filter-menu
                                        *ngIf="!col.filter"
                                        [column]="column"
                                        [filter]="filter"
                                        [filterService]="filterService"
                                        [extra]="false"
                                    > </kendo-grid-string-filter-menu>
                                    <kendo-grid-boolean-filter-menu
                                        *ngIf="col.filter == 'boolean'"
                                        [column]="column"
                                        [filter]="filter"
                                        [filterService]="filterService"
                                    > </kendo-grid-boolean-filter-menu>
                                </ng-template>
                                <ng-template
                                    kendoGridHeaderTemplate
                                    let-column
                                    let-columnIndex="columnIndex"
                                >
                                    <ng-container *ngIf="col.title">{{col.title | translate}}</ng-container>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="{{'Assignment date' | translate}}">
                                <ng-template
                                    kendoGridCellTemplate
                                    let-dataItem
                                >
                                    {{dataItem.assignment_date && dataItem.assignment_date.toString() != '0000-00-00 00:00:00' ? (dataItem.assignment_date | date):''}}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column
                                *ngIf="true"
                                title="{{'Actions' | translate}}"
                                [width]="100"
                                headerClass="text-center"
                            >
                                <ng-template
                                    kendoGridCellTemplate
                                    let-dataItem
                                >
                                    <div class="d-flex justify-content-center">
                                        <i
                                            *ngIf="true"
                                            [hasPermission]="['members.update']"
                                            [toRemove]="true"
                                            class="fas fa-pen text-info p-2"
                                            role="button"
                                            (click)="onEditButtonClicked(dataItem)"
                                        ></i>
                                        <i
                                            *ngIf="true"
                                            [hasPermission]="['members.update']"
                                            [toRemove]="true"
                                            class="fas fa-trash text-danger p-2"
                                            role="button"
                                            (click)="onCommunitteeMemberDeleteButtonClicked(dataItem)"
                                        ></i>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>



                        <!-- <app-modal-confirm #deleteModal content="{{'Are you sure to delete' | translate}}" (confirm)="onConfirmDelete($event)"></app-modal-confirm> -->
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab
                    *ngIf="hasOneRole(['finance']) && member"
                    title="{{'Financial Informations' | translate}}"
                >
                    <ng-template
                        kendoTabContent
                        *ngIf="member.bank_datas"
                    >
                        <div class="row">
                            <div class="col-sm-3">
                                <kendo-label text="{{'IBAN Profesional' | translate}}"></kendo-label>
                                <kendo-textbox
                                    [disabled]="!hasOneRole(['admin'])"
                                    name="iban_pro"
                                    [(ngModel)]="member.bank_datas.iban_pro"
                                ></kendo-textbox>
                            </div>
                            <div class="col-sm-3">
                                <kendo-label text="{{'IBAN Private' | translate}}"></kendo-label>
                                <kendo-textbox
                                    [disabled]="!hasOneRole(['admin'])"
                                    name="iban_priv"
                                    [(ngModel)]="member.bank_datas.iban_priv"
                                ></kendo-textbox>
                            </div>
                            <div class="col-sm-3">
                                <kendo-label text="{{'BCE' | translate}}"></kendo-label>
                                <kendo-textbox
                                    [disabled]="!hasOneRole(['admin'])"
                                    name="BCE"
                                    [(ngModel)]="member.bank_datas.BCE"
                                ></kendo-textbox>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <!-- <div class="col-sm-3">
                                <kendo-label text="{{'No refund' | translate}}"></kendo-label>
                                <kendo-switch class="ms-3" [(ngModel)]="member.noRefund"></kendo-switch>
                            </div>
                            <div class="col-sm-3" [hidden]="element.noRefund">
                                <kendo-label text="{{'Refund Location' | translate}}"></kendo-label>
                                <kendo-textbox name="refundLocation" [(ngModel)]="member.refundLocation"></kendo-textbox>
                            </div> -->
                            <div class="col-sm-3">
                                <kendo-label text="{{'Register No.' | translate}}"></kendo-label>
                                <kendo-textbox
                                    [disabled]="!hasOneRole(['admin'])"
                                    name="registerNo"
                                    [(ngModel)]="member.registerNo"
                                ></kendo-textbox>
                            </div>
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-8">
                                        <kendo-label text="{{'Does not wish to be reimbursed' | translate}}"></kendo-label>
                                    </div>
                                    <div class="col-4">
                                        <kendo-switch
                                            [disabled]="!hasOneRole(['admin'])"
                                            name="notBeRefunded"
                                            [(ngModel)]="member.not_be_refunded"
                                        ></kendo-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div *ngIf="element.persons_addresses.length>1" class="row">
                                    <div class="col-8">
                                        <kendo-label text="{{'Professional address calculation base' | translate}}"></kendo-label>
                                    </div>
                                    <div class="col-4">
                                        <kendo-switch
                                            [disabled]="!hasOneRole(['admin'])"
                                            name="usedBaseCalculatioProfessionnalAddress"
                                            [(ngModel)]="member.used_base_calculation_professionnal_address"
                                        ></kendo-switch>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="{{'Communications' | translate}}">
                    <ng-template kendoTabContent>
                        <app-person-communication-form
                            [(person)]="element"
                        ></app-person-communication-form>
                    </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab
                    *ngIf="isAssistant"
                    title="{{ 'Members' | translate }}"
                >
                    <ng-template kendoTabContent>
                        <app-grid
                            #membersGrid
                            [editable]="false"
                            [deletable]="false"
                            [columns]="membersColumns"
                            [service]="committeesMembersService"
                            (doubleClick)="onMembersDoubleClick($event)"
                            [baseSorts]="membersSorts"
                            [baseFilters]="membersFilters"
                        ></app-grid>
                    </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab title="{{'Other' | translate}}" *ngIf="member">
                    <ng-template kendoTabContent>
                        <div class="row">
                            <div class="col-6">
                                <kendo-label text="{{'Special remarks' | translate}}"></kendo-label>
                                <kendo-editor
                                    [(value)]="member.comment"
                                    placeholder="{{'Special remarks' | translate}}"
                                ></kendo-editor>
                            </div>
                            <div class="col-6">
                                <kendo-label text="{{'Special remarks (additional information)' | translate}}"></kendo-label>
                                <kendo-editor
                                    [(value)]="member.comment_more"
                                    placeholder="{{'Special remarks (additional information)' | translate}}"
                                ></kendo-editor>
                            </div>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </div>
    </kendo-card-body>
</kendo-card>

<app-modal-confirm
    #deleteModal
    content="{{'Are you sure to delete' | translate}}"
    (confirm)="confirmDelete()"
></app-modal-confirm>

<app-modal-confirm
    #confirmChangeToMemberModal
    content="{{'Are you sure you want to convert this person' | translate}}"
    (confirm)="onConfirmToMemberClicked()"
></app-modal-confirm>

<app-modal-confirm
    #confirmChangeToPersonModal
    content="{{'Are you sure you want to convert this member' | translate}}"
    (confirm)="onConfirmToPersonClicked()"
></app-modal-confirm>

<app-modal
    #assignModal
    title="Assignation"
    (confirm)="onConfirmAssignationButtonClicked()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Committee' | translate}}"></kendo-label>
        <kendo-dropdownlist
            class="mb-3"
            name="committee"
            [data]="committeesHelper.filteredDatas"
            [filterable]="true"
            (filterChange)="committeesHelper.handleFilter('displayName.fr', $event)"
            [(ngModel)]="assignModal.data.instance"
            (ngModelChange)="onInstanceChange($event.id)"
            textField="displayName.fr"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'Section' | translate}}"></kendo-label>
        <kendo-dropdownlist
            class="mb-3"
            name="section"
            [disabled]="sectionsHelper.filteredDatas.length === 0"
            [data]="sectionsHelper.filteredDatas"
            [filterable]="true"
            (filterChange)="sectionsHelper.handleFilter('displayName.fr', $event)"
            [(ngModel)]="assignModal.data.section"
            textField="displayName.fr"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'CommitteeRole' | translate}}"></kendo-label>
        <kendo-dropdownlist
            class="mb-3"
            name="committeeRole"
            [data]="committeeRolesHelper.filteredDatas"
            [filterable]="true"
            (filterChange)="committeeRolesHelper.handleFilter('displayName.fr', $event)"
            [(ngModel)]="assignModal.data.committee_role"
            textField="displayName.fr"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'Date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="assignModal.data.assignment_date"></kendo-datepicker>

    </ng-template>
</app-modal>

<app-modal
    #editCommitteeModal
    title="Assignation"
    (confirm)="onConfirmEditButtonClicked()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Committee' | translate}}"></kendo-label>
        <kendo-dropdownlist
            class="mb-3"
            [data]="committeesHelper.filteredDatas"
            [disabled]="true"
            [filterable]="true"
            (filterChange)="committeesHelper.handleFilter('displayName.fr', $event)"
            name="committee"
            [(ngModel)]="editCommitteeModal.data.instance"
            textField="displayName.fr"
            valueField="id"
        ></kendo-dropdownlist>

        <ng-container *ngIf="editCommitteeModal.data.section">
            <kendo-label text="{{'Section' | translate}}"></kendo-label>
            <kendo-dropdownlist
                class="mb-3"
                [data]="sectionsHelper.filteredDatas"
                [disabled]="true"
                [filterable]="true"
                (filterChange)="sectionsHelper.handleFilter('displayName.fr', $event)"
                name="section"
                [(ngModel)]="editCommitteeModal.data.section"
                textField="displayName.fr"
                valueField="id"
            ></kendo-dropdownlist>
        </ng-container>

        <kendo-label text="{{'CommitteeRole' | translate}}"></kendo-label>
        <kendo-dropdownlist
            class="mb-3"
            [data]="committeeRolesHelper.filteredDatas"
            [filterable]="true"
            name="committeeRole"
            [(ngModel)]="editCommitteeModal.data.committee_role"
            textField="displayName.fr"
            (filterChange)="committeeRolesHelper.handleFilter('displayName.fr', $event)"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'Date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="editCommitteeModal.data.assignment_date"></kendo-datepicker>
    </ng-template>
</app-modal>

<app-modal-confirm
    #deleteCommitteeMemberModal
    content="{{'Are you sure to delete' | translate}}"
    (confirm)="onConfirmDeleteCommitteeMember()"
></app-modal-confirm>
<app-change-person-primary-email-modal
  #changePrimaryMailModal
  [personId]="element.id"
  (onPrimaryEmailChanged)="onPrimaryEmailChanged($event)"
>
</app-change-person-primary-email-modal>
