import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppNotificationService } from '../app-notification.service';
import { State } from '@progress/kendo-data-query';
import { catchError, EMPTY, Observable, tap, map } from 'rxjs';
import { HttpRequestOptions } from './http-request-options';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService<T> {
  protected abstract baseUri: string;
  protected abstract activator(anonymousObject: Object): T;

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService
  ) {}

  all(state: State | null = null): Observable<Array<T>> {
    const options: HttpRequestOptions = {
      responseType: 'json',
      params: state != null ? new HttpParams().set('params', JSON.stringify(state)) : undefined
    };


    return this.http.get<Object[]>(this.baseUri, options).pipe(
      tap({
        error: this.tapError
      }),
      map((response: Object[]) => {
        return this.activateList(response);
      }),
      catchError(this.tapError)
    );
  }

  private activateList(anonymousObjects: Object[]): T[] {
    return anonymousObjects.map(this.activator);
  }

  protected tapError = (e: HttpErrorResponse) => {
    const message = typeof e.error === 'string' ? e.error : (e.error?.message ?? e.error?.data ?? e.message);
    this.notificationService.showNotification(message, AppNotificationService.STYLE_ERROR);
    return EMPTY;
  };

  protected getUrl(subUri : string = "") : string{
    let url = `${environment.backendBaseUrl}${this.baseUri}`;
    if(!subUri) return url;
    return `${url}/${subUri}`

  }
}
