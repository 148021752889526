<kendo-card width="100%">
    <div class="row pt-2">
        <div class="col-3 text-center text-primary">
            <i class="fas fa-briefcase" style="font-size: 4.5rem; opacity: 25%;"></i>
            <div class="bg-primary font-weight-bold h6 text-center text-uppercase text-white m-0 py-2 pr-0">{{ relation.person_relation_type.displayName.fr }}</div>
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-9">
                    <h5 class="text-primary font-weight-bold mb-4">{{ relation.relation.person_title.displayName.fr }} {{ relation.relation.fullname }}</h5>
                </div>
                <div [hasPermission]="['members.update']" [toRemove]="true" class="col-3 d-flex justify-content-end align-items-start">
                    <button class="btn btn-small btn-link" #anchor (mouseenter)="showMenu = !showMenu"><i class="fas fa-ellipsis-h"></i></button>
                    <kendo-popup [anchor]="anchor" *ngIf="showMenu">
                        <div class="p-3" (mouseleave)="showMenu = !showMenu">
                            <div class="row" (click)="onEditRelationButtonClicked()" role="button">
                                <div class="col-sm-1">
                                    <i class="fas fa-pen me-2"></i>
                                </div>
                                <div class="col">
                                    <span>{{'Edit' | translate}}</span>
                                </div>
                            </div>
                            <div class="row" (click)="onDeleteRelationButtonClicked()" role="button">
                                <div class="col-sm-1">
                                    <i class="fas fa-trash me-2"></i>
                                </div>
                                <div class="col">
                                    <span>{{'Delete' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </kendo-popup>
                </div>
            </div>

            <div *ngIf="relation.relation.primary_email" class="mb-1">
                <i class="fas fa-at text-primary me-2"></i>
                <span class="font-italic h6"> {{ relation.relation.primary_email }}</span>
            </div>
            <div *ngIf="relation.relation.pro_phone" class="mb-1">
                <i class="fas fa-phone text-primary me-2"></i>
                <span class="font-italic h6"> {{ relation.relation.pro_phone }}</span>
            </div>
        </div>
    </div>
</kendo-card>

<app-modal-confirm #deleteModal content="{{'Are you sure to delete this relation ?' | translate}}" (confirm)="deleteRelation()"></app-modal-confirm>