import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/ui/modal-confirm/modal-confirm.component';
import { PersonService } from '../person.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-person-primary-email-modal',
  templateUrl: './change-person-primary-email-modal.component.html',
  styleUrls: ['./change-person-primary-email-modal.component.scss']
})
export class ChangePersonPrimaryEmailModalComponent {

  @Input({ required: true }) personId!: number;
  @Input() opened: boolean = false;

  @Output() onPrimaryEmailChanged : EventEmitter<string> = new EventEmitter<string>();

  protected changeMailRequestSent = false;

  @ViewChild("confirmModal") confirmModal!: ModalConfirmComponent;

  constructor(
    private personService: PersonService,
    private notificationService: AppNotificationService,
    private translationService: TranslatePipe
  ) { }


  protected form: FormGroup = new FormGroup({
    newEmail: new FormControl(),
    sendInviteMail: new FormControl(),
  });

  protected cancel() {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  protected confirm() {
    this.confirmModal.toggle();
  }

  protected send() {
    this.confirmModal.toggle();
    this.changeMailRequestSent = true;
    this.personService.changePrimaryMail(this.personId, this.form.get('newEmail')?.value, this.form.get('sendInviteMail')?.value)
      .subscribe({
        error: this.handleHttpError.bind(this),
        next: this.handleSuccess.bind(this)
      });
  }

  private handleHttpError(err: HttpErrorResponse) {
    const basicErrorMessage = "Unknown error, please contact administrator if this happens again.";
    let errorMessage = "";
    let domainError = err.error;
    if (err.status == 422) {
      for(let key in domainError){
        if (!domainError.hasOwnProperty(key)) continue;
        errorMessage += domainError[key].reduce((acc : string,curr: string )=>`${acc} ${this.translationService.transform(curr)}`,"");
      }
    }else if (typeof(domainError) == 'string'){
      errorMessage = domainError;
    }else{
      errorMessage = err.message;
    }

    this.notificationService.showNotification(errorMessage.length == 0 ? basicErrorMessage : errorMessage,"error");

    this.changeMailRequestSent = false;
  }

  private handleSuccess(){
    this.notificationService.showNotification("Email address changed successfully.", "success");
    this.onPrimaryEmailChanged.emit(this.form.get('newEmail')?.value);
    this.opened = false;
  }

}
