

export class ToolbarAction {
  code?: string;

  text?: string;
  textStyle?: any;
  textClass?: string;

  icon?: string;
  iconStyle?: any;
  iconClass?: string;

  btnStyle?: 'outline' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-light' |
    'dark' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'link';
  btnClass?: string;

  image?: string;
  imageStyle?: any;
  imageClass?: string;

  click?: Function;
  atEnd?: boolean;
  constructor(object: Partial<ToolbarAction>) {
    Object.assign(this, object);
  }
}
