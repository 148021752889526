<div class="row my-3">
    <div class="col-sm-3">
        <kendo-label text="{{'Destinataire revue wallonie' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="person.newsletter_revue_wallonie" (ngModelChange)="person.newsletter_revue_wallonie_count = $event ? 1 : 0"></kendo-switch>
    </div>
    <div class="col-sm-3">
        <kendo-label text="{{'Nombre d\'exemplaires' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-numerictextbox [disabled]="!authenticationService.hasOnePermission(['members.update']) || !person.newsletter_revue_wallonie" class="ms-3" [(ngModel)]="person.newsletter_revue_wallonie_count" [decimals]="0" format="\#" [autoCorrect]="true" [min]="person.newsletter_revue_wallonie ? 1 : 0" [max]="person.newsletter_revue_wallonie ? 1000 : 0"></kendo-numerictextbox>
    </div>
    <div class="col-sm-3">
        <kendo-label text="{{'Send mail to private address' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="person.send_mail_to_private_address"></kendo-switch>
    </div>
</div>
<div class="row my-3">
    <div class="col-sm-3">
        <kendo-label text="{{'Destinataire newsletters externes' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="person.newsletter_externes" ></kendo-switch>
    </div>
    <div class="col-sm-3">
        <kendo-label text="{{'Invité aux activités du CESE' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="person.invite_for_cese_activities"></kendo-switch>
    </div>
    <div class="col-sm-3">
        <kendo-label text="{{'Mentions légales' | translate}}"></kendo-label>
    </div>
    <div class="col-sm-1">
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="person.legal_mention"></kendo-switch>
    </div>
</div>
