<div class="container-fluid">
  <div class="text-center">
    <form class="form-signin">
      <img class="mb-4" src="/assets/logo.png">
      
      
    </form>
  </div>
</div>

<!-- Footer -->
<footer class="sticky-footer">
  <div class="container my-auto">
    <div class="copyright text-center my-auto">
      <span>Copyright &copy; <a href="https://syslink.be">Syslink</a> 2024</span>
    </div>
  </div>
</footer>