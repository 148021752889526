import { EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { CellClickEvent, DataStateChangeEvent, ExcelExportEvent, GridComponent, GridDataResult } from "@progress/kendo-angular-grid";
import { FilterDescriptor, orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { finalize } from "rxjs";
import { AppApiService } from "src/app/app-api.service";
import { AppNotificationService } from "src/app/app-notification.service";
import { environment } from "src/environments/environment";

export class KendoComponentHelper {

    datasLoaded: EventEmitter<any[]> = new EventEmitter<any[]>();

    // Selection
    // ---------
    public selectable: any = { mode: 'multiple' };
    public selectedBy: string = 'id';
    public selection: any[] | null = null;
    public onSelectionChange: (selection: string[]) => {} | null = (selection: string[]) => null;

    // Pagination
    // ----------
    public pageable: any = true;
    public scrollable: any = 'none';

    // Sorting
    // -------
    public sortable: any = {
        allowUnsort: true,
        mode: 'multiple'
    };
    public sort: SortDescriptor[] = [
        {
            field: "id",
            dir: "asc",
        },
    ];;

    // Filtering
    // ---------
    public filterable: any = true;
    public filters: any[] = [];

    // Editable Cell
    // -------------
    public onCellClicked: (element: any) => {} | null = (element: any) => null;


    public datas: any[] | null = null;
    public filteredDatas: any[] = [];


    public loading: boolean = false;
    public dataResult: GridDataResult | null = null;
    public state: any = {
        skip: 0,
        take: 20,
    };

    public service: AppApiService | undefined;
    public grid: GridComponent | undefined;

    constructor(
        private notificationService?: AppNotificationService
    ) {
    }

    // Grid
    // ----
    public cellClickHandler(event: CellClickEvent) {
        if (!event.isEdited) {
            this.onCellClicked(event);
            event.sender.editCell(event.rowIndex, event.columnIndex, new UntypedFormBuilder().group({
                engine: event.dataItem.engine,
                cabine: event.dataItem.cabine
            }));
        }
    }

    public cellCloseHandler(args: any) {
        const { formGroup, dataItem } = args;

        if (!formGroup.valid) {
            args.preventDefault();
        } else if (formGroup.dirty) {
        }
    }


    public dataStateChange(eventState: DataStateChangeEvent): void {
        this.state = eventState;
        this.updateGridDatas(this.datas);
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.updateGridDatas(this.datas);
    }

    public updateGridDatas(datas?: any[] | null): void {
        this.loading = true;
        // if (!datas) {
        this.service!.get(this.state).then(r => {
            this.dataResult = {
                data: r.data,
                total: r.total
            };
            this.loading = false;
            this.datasLoaded.emit(r.data);
        }).catch((e: any) => {
            this.loading = false;
        });
        // }
        // else {
        //     this.datas = datas;
        //     this.dataResult = process(this.datas, this.state);
        //     this.loading = false;
        // }


    }

    public search(inputValue: string | null, columns: string[]): void {
        this.loading = true;

        let columnFilters: any[] = [];
        columns.forEach((c: any) => columnFilters.push({
            field: c,
            operator: "contains",
            value: inputValue,
            ignoreCase: true
        }));
        this.state.filter = {
            logic: "or",
            filters: columnFilters,
        };
        this.updateGridDatas();
        this.loading = false;
    }

    public export() {
        this.notificationService?.toggleLoader();
        let exportState = Object.assign({}, this.state);
        delete exportState.skip;
        delete exportState.take;

        this.service?.export(exportState).pipe(finalize(()=>this.notificationService?.toggleLoader)).subscribe();
    }

    public selectedKeysChange(selectedRows: string[]) {
        this.selection = selectedRows;
        selectedRows = [];
        this.onSelectionChange(this.selection);
    }

    // // DROPDOWN
    // // --------
    // public handleFilter(field: string, value: any) {
    //     if (!isNaN(value) &&
    //         parseInt(value) == value &&
    //         !isNaN(parseInt(value, 10))) {
    //         this.filteredDatas = this.datas!.filter(
    //             (s) => s.id == value
    //         );
    //     }
    //     else {
    //         this.filteredDatas = this.datas!.filter(
    //             (s) => s[field]?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    //         );

    //     }
    // }

    getValueFromNotation(obj: any, is: string | any[], value: any = undefined): any {

        if (typeof is == 'string')
            return this.getValueFromNotation(obj, is.split('.'), value);
        else if (is.length == 1 && value !== undefined)
            return obj[is[0]] = value;
        else if (is.length == 0)
            return obj;
        else
            return this.getValueFromNotation(obj[is[0]], is.slice(1), value);

    }
    async handleFilter(fieldname: string, value: any) {
        if (!this.datas && this.service) {
            this.loading = true;
            this.addFilter('and', {
                field: fieldname,
                operator: 'contains',
                value: value,
            })

            this.filteredDatas = (await this.service.all(this.state))?.data || [];
            this.loading = false;
        }
        else {
            this.filteredDatas = this.datas!.filter((s) => {
                return this.getValueFromNotation(s, fieldname).toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
        }
    }

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };

    static buildFilters(filters: FilterDescriptor[]): any {

        const formattedFilters: any = [];
        filters.forEach(element => {
            formattedFilters.push(
                {
                    'field': element.field,
                    'value': element.value,
                    'operator': element.operator,
                }
            );
        });
        return { filters: formattedFilters, 'logic': 'and' };
    }

    public addFilter(condition: 'and' | 'or', filter: FilterDescriptor) {
        this.state.filter = KendoComponentHelper.buildFilters([
            filter
        ])
    }

}
