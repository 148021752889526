import { Component, EventEmitter, Output } from '@angular/core';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss']
})
export class BooleanCellComponent extends BaseCell<boolean> {
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
