import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/http/api.service';
import { Country } from './country';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ApiService<Country>{
  protected baseUri: string = 'base/countries'
  protected activator = Country.fromResponse;


  public findByCode(code : string) : Observable<Country>{
    let subUri = `byCode/${code}`;
    return this.http.get(this.getUrl(subUri)).pipe(tap({error:(error)=>this.handleFindByCodeError(error)}),map(this.activator));
  }

  private handleFindByCodeError(error : HttpErrorResponse){
    let message = typeof error.error === 'string' ? error.error : (error.error?.message ?? error.error?.data ?? error.message);
    if(error.status === 404){
      message = "Address' country isn't present in the database, the address can't be saved."
    }else if(error.status == 422){
      message = "The country's code format can't be processed by the server, please contact an administrator if this happens again.";
    }
    this.notificationService.showNotification(message, AppNotificationService.STYLE_ERROR);
    return EMPTY;
  }
}
