import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentAttendeesService extends AppApiService {
  public url: string | null = 'cesew/appointmentAttendees';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
    private appApiService: AppApiService,
    private authenticationService: AuthenticationService
  ) {
    super(http, notificationService);
  }

  create(element: any): Promise<any> {
    if(!element?.appointment_id){
      throw "Can't add attendee without appointment_id";
    }
    if(!element?.person_id){
      throw "Cant add attendee without its id.";
    }
    return this.post({},`aad/user/events/${element.appointment_id}/attendees/${element.person_id}`);
  }
}
