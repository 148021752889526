import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { ToolbarService } from 'src/app/ui/toolbar/toolbar.service';
import { MembersService } from '../../members/members.service';
import { CommitteesMembersService } from '../committees-members.service';
import { CommitteesRolesService } from '../committees-roles.service';
import { CommitteesService } from '../committees.service';
import { BreadcrumbsService } from 'src/app/ui/breadcrumbs/breadcrumbs.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AadService } from 'src/app/core/aad/aad.service';
import { BreadcrumbEvents } from 'src/app/ui/breadcrumbs/breadcrumb-events';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { CommitteesAppointmentsStatisticsModalComponent } from '../committees-appointments-statistics-modal/committees-appointments-statistics-modal.component';

@Component({
  selector: 'app-committee-details',
  templateUrl: './committee-details.component.html',
  styleUrls: ['./committee-details.component.scss']
})
export class CommitteeDetailsComponent extends PageComponent implements OnInit {
  public element: any;
  public element_id?: string | number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: AppNotificationService,
    public committeesService: CommitteesService,
    public committeesMembersService: CommitteesMembersService,
    private memberService: MembersService,
    private committeeRolesService: CommitteesRolesService,
    private aadService: AadService,
    private authenticationService: AuthenticationService
  ) {
    super();
    this.route.data.subscribe(({ element }) => {
      this.element = element?.data;
      this.breadcrumbsService.parser = (segment: string) => {
        return {
          text: isNaN(+segment) ? segment.charAt(0).toUpperCase() + segment.slice(1) : this.element?.displayName.fr,
          click: (e: any) => { this.router.navigateByUrl('admin/' + segment) }
        }
      };
      this.eventsService.emit(BreadcrumbEvents.Reload);
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.params.subscribe((params) => {
      this.element_id = params.id;

      if (this.element_id === 'new') {
        this.element = {
          type: "instance",
          code: "",
          displayName: {
            en: "",
            fr: ""
          }
        }
      } else if (!this.element) {
        this.notificationService.showNotification("Record Not Found", 'error');
        this.router.navigateByUrl('admin/committees');
      }

      this.element_id = params.id;

      this.committeeMembersFilters = {
        logic: 'and',
        filters: [
          {
            field: 'committee_id',
            operator: 'eq',
            value: this.element_id,
          },
        ],
      };

      this.committeeSectionsFilters = {
        logic: 'and',
        filters: [
          {
            field: 'parent_id',
            operator: 'eq',
            value: this.element_id,
          },
        ],
      };

      // this.addAction({ code: 'save', text: 'Save', icon: 'save', click: () => this.onCommitteeSaveButtonClicked(), style: 'outline-primary' });

      this.updateAvailableMembers();
      forkJoin({
        committeeRoles: this.committeeRolesService.get()
      }).subscribe((responses: any) => {
        // Assign Members
        // --------------        
        this.committeeRolesHelper.datas = this.committeeRolesHelper.filteredDatas = responses.committeeRoles.data;

        // Committee Members Grid
        // ----------------------
        this.committeeMembersGrid.state.filter = KendoComponentHelper.buildFilters([
          { field: 'committee_id', operator: 'eq', value: this.element_id }
        ])
        this.committeeMembersGrid.updateGridDatas();

        // Sections Grid
        // -------------
        this.committeeSectionGrid.state.filter = KendoComponentHelper.buildFilters([
          { field: 'type', operator: 'eq', value: 'section' },
          { field: 'parent_id', operator: 'eq', value: this.element_id }
        ])
        this.committeeSectionGrid.updateGridDatas();
      });
    });

    if (this.hasOneRole(['admin'])) {
      this.committeeMembersGridActions.push({ code: 'show-history-member-committees', text: 'History', icon: 'box-archive', click: () => this.onShowHistoryMemberCommitteesBtn(), atEnd: "true" });
    }
    this.initActions();
  }

  private initActions() {
    this.toolbarService.reset();
    if (this.element.id) {
      this.addAction({ code: 'appointmentsStatistics', text: 'Appointments statistics', icon: 'chart-simple', click: () => this.onAppointmentsStatisticsButtonClicked(), btnStyle: 'outline-primary' });
    }
  }


  public hasOneRole(roles: string[]) {
    return this.authenticationService.hasOneRole(roles);
  }

  private updateAvailableMembers() {
    forkJoin({
      members: this.memberService.get()
    }).subscribe((responses: any) => {
      const members = responses.members.data
        .filter((member: any) => {
          return !member.committees_members.map((association: any) => association.committee_id).includes(this.element.id)
        });

      this.membersHelper.datas = this.membersHelper.filteredDatas = members;
    });
  }

  // Committees
  // ----------
  public onCommitteeSaveButtonClicked() {
    if (!this.element.id) {
      this.element.code = this.element.displayName.fr
      this.element.displayName.en = this.element.displayName.fr

      this.committeesService.create(this.element).then((r: any) => {
        this.element = r.data;
        this.element_id = this.element.id;

        this.router.navigateByUrl('admin/committees/' + this.element.id);
      });
    } else {
      this.committeesService.update(this.element).then((r: any) => {
        this.notificationService.showNotification('Record Updated', 'success');
        this.ngOnInit();
      });
    }
  }

  // Committee Members
  // -----------------
  @ViewChild('committeeMembersGrid') committeeMembersGrid!: AppGridComponent;
  public committeeMembersFilters!: CompositeFilterDescriptor;
  public committeeMembersColumns: any[] = [
    {
      field: "member.person.person_gender.displayName", title: "Gender", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 2, tablename: "person_genders", foreignKey: "people.gender_id" }
      ]
    },
    {
      field: "member.person.lastname", title: "Lastname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: "member.person.firstname", title: "Firstname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: "committee_role.displayName.fr", title: "Role", joins: [
        { sequence: 1, tablename: "committee_roles", foreignKey: "committees_members.committee_role_id" }
      ]
    },
    {
      field: 'member.person.person_tenure.displayName.fr', title: 'Tenure', joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 4, tablename: "person_tenures", foreignKey: "people.tenure_id" }
      ]
    }
  ];
  public committeeMembersSorts = [
    {
      dir: 'asc', field: 'member.person.lastname', joins: [
        { sequence: 1, tablename: "members", foreignKey: "committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    }
  ]
  public committeeMembersGridActions: any[] = [{ code: 'add-address', text: 'Assign a Member', icon: 'address-book', click: () => this.onAddCommitteeMemberBtn(), style: 'outline' }]

  @ViewChild("addCommitteeMemberModal") addCommitteeMemberModal: ModalComponent = new ModalComponent();
  @ViewChild("editCommitteeMemberModal") editCommitteeMemberModal: ModalComponent = new ModalComponent();
  public membersHelper: KendoComponentHelper = new KendoComponentHelper();
  public committeeRolesHelper: KendoComponentHelper = new KendoComponentHelper();

  public onAddCommitteeMemberBtn() {
    this.addCommitteeMemberModal.data = {}
    this.addCommitteeMemberModal.data.assignment_date = new Date();
    this.addCommitteeMemberModal.toggle();
  }

  public onConfirmAddCommitteeMemberBtn() {
    this.committeesMembersService.create({
      member_id: this.addCommitteeMemberModal.data.member.id,
      committee_id: this.element.id,
      committee_role_id: this.addCommitteeMemberModal.data.committee_role.id,
      assignment_date: this.committeesMemberFormatDate(this.addCommitteeMemberModal.data.assignment_date)
    }).then(r => { }).finally(() => {
      this.addCommitteeMemberModal.toggle();
      this.committeeMembersGrid.updateGridDatas();
      this.updateAvailableMembers();
      this.notificationService.showNotification("Record Created", 'success');

      this.aadService.addMemberToGroup(this.element, this.addCommitteeMemberModal.data.member.person);
    });
  }

  public onDeleteCommitteeMemberBtn(commiteeMemberLink: any) {
    this.committeesMembersService.deleteAssoc({ member_id: commiteeMemberLink.member_id, committee_id: commiteeMemberLink.committee_id, committee_role_id: commiteeMemberLink.committee_role_id }).then((r: any) => {
    }).finally(() => {
      this.committeeMembersGrid.deleteModal.toggle()
      this.committeeMembersGrid.updateGridDatas();
      this.updateAvailableMembers();
      this.notificationService.showNotification("Record Deleted", 'success');

      this.aadService.removeMemberFromGroup(this.element, commiteeMemberLink.member.person);
    });
  }

  public onCommitteeMemberDoubleClick(committee_member: any) {
    this.router.navigateByUrl('admin/members/' + committee_member.member.id);
  }

  public onCommitteeMemberEditBtn(committee_member: any) {
    this.editCommitteeMemberModal.data = committee_member;
    if (committee_member.assignment_date.toString() == '0000-00-00 00:00:00') {
      this.editCommitteeMemberModal.data.assignment_date = undefined;
    }
    else {
      this.editCommitteeMemberModal.data.assignment_date = new Date(committee_member.assignment_date);
    }
    this.editCommitteeMemberModal.toggle();
  }

  public onComfirmCommitteeMemberEditBtn() {
    this.committeesMembersService.updateAssoc({
      'old': {
        member_id: this.editCommitteeMemberModal.data.member.id,
        committee_id: this.element.id,
        committee_role_id: this.editCommitteeMemberModal.data.committee_role_id,
      },
      'new': {
        member_id: this.editCommitteeMemberModal.data.member.id,
        committee_id: this.element.id,
        committee_role_id: this.editCommitteeMemberModal.data.committee_role.id,
        assignment_date: this.committeesMemberFormatDate(this.editCommitteeMemberModal.data.assignment_date)
      }
    }).then(r => {
      this.editCommitteeMemberModal.toggle();
      this.committeeMembersGrid.updateGridDatas();
      this.notificationService.showNotification("Record Updated", 'success');
    });
  }

  private committeesMemberFormatDate(date: Date) {
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0') + ' ' +
      date.getHours().toString().padStart(2, '0') + ':' +
      date.getMinutes().toString().padStart(2, '0') + ':' +
      date.getSeconds().toString().padStart(2, '0');
  }

  private onShowHistoryMemberCommitteesBtn() {
    this.historyCommitteeMemberModal.toggle();
  }

  // Sections
  // --------
  @ViewChild('committeeSectionGrid') committeeSectionGrid!: AppGridComponent;
  public committeeSectionsFilters!: CompositeFilterDescriptor;
  public committeeSectionsColumns: any[] = [{ field: "displayName.fr", title: "Nom" }];
  public committeeSectionsSorts = [{ dir: 'asc', field: 'displayName.fr' }];
  public committeeSectionsGridActions: any[] = [
    // { code: 'add-section', text: 'Add a section', icon: 'address-book', click: () => this.onAddCommitteeSectionBtn(), style: 'outline' }
  ];

  @ViewChild("addCommitteeSectionModal") addCommitteeSectionModal: ModalComponent = new ModalComponent();
  @ViewChild("historyCommitteeMemberModal") historyCommitteeMemberModal: ModalComponent = new ModalComponent();

  public onEditCommitteeSectionBtnClicked(section: any) {
    this.router.navigateByUrl('admin/sections/' + section.id);
  }

  public onCommitteeSectionDoubleClick(section: any) {
    this.router.navigateByUrl('admin/sections/' + section.id);
  }

  public onAddCommitteeSectionBtn() {
    this.addCommitteeSectionModal.data = {
      type: 'section',
      code: "",
      displayName: {
        en: "",
        fr: "",
      },
      parent_id: this.element_id
    };
    this.addCommitteeSectionModal.toggle();
  }

  public onCommitteeSectionAddBtn() {
    this.addCommitteeSectionModal.data.code = this.addCommitteeSectionModal.data.displayName.en = this.addCommitteeSectionModal.data.displayName.fr

    this.committeesService.create(this.addCommitteeSectionModal.data).then((r: any) => {
      this.addCommitteeSectionModal.toggle();
      this.committeeSectionGrid.updateGridDatas();
      this.notificationService.showNotification('Record Created', 'success');
    });
  }

  // Export appointment statistics
  @ViewChild('committeeAppointmentsStatisticsModal') committeeAppointmentsStatisticsModal?: CommitteesAppointmentsStatisticsModalComponent;
  public onAppointmentsStatisticsButtonClicked(){
    if(!this.committeeAppointmentsStatisticsModal) return;
    this.committeeAppointmentsStatisticsModal.committeeAppointmentsStatisticsModal.toggle();
  }

  // -----------------------------------
}