import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DataStateChangeEvent,
  GridComponent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { environment } from 'src/environments/environment';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalComponent } from '../modal/modal.component';
import { AppToolbarComponent, ExportCallback } from '../toolbar/toolbar.component';
import { ToolbarService } from '../toolbar/toolbar.service';
import { StatePersistingService } from './state-persisting.service';
import { fromDotNotation } from 'src/app/core/tools';
import { ToolbarAction } from '../toolbar/ToolbarAction';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class AppGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() columns: any[] = [];
  @Input() data?: any[];
  @Input() service?: AppApiService;
  @Input() redirectUrl: string | null = null;
  @Input() deletable: boolean = true;
  @Input() editable: boolean = true;
  @Input() actions: ToolbarAction[] = [];
  @Input() usedApiRouteList: boolean = false;

  @Output() add: EventEmitter<AppGridComponent> = new EventEmitter<AppGridComponent>();
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() doubleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBooleanClick: EventEmitter<{ field: string, dataItem: any }> = new EventEmitter<{ field: string, dataItem: any }>();

  // Options
  // -------
  @Input() searchable: boolean = false;
  @Input() exportable: boolean = false;

  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('toolbar') toolbar!: AppToolbarComponent;
  @ViewChild('deleteModal') deleteModal: ModalConfirmComponent =
    new ModalConfirmComponent();

  public loading: boolean = false;
  public dataResult: GridDataResult | null = null;
  public state: State = {
    skip: 0,
    take: 20
  };

  @Input() baseFilters!: CompositeFilterDescriptor;
  @Input() baseSorts !: any;

  // Double click
  // ------------
  public clickedRowItem: any;

  onCellClick(e: any) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick(dataItem: any) {
    if (this.doubleClick.observed) {
      this.doubleClick.emit(this.clickedRowItem);
    } else {
      this.onEditButtonClicked(this.clickedRowItem);
    }
  }

  // Toolbar
  // -------
  isToolbarhidden() {
    return this.actions.length <= 0 || (!this.exportable && !this.searchable)
  }
  public searchValue: string = '';

  // Selection
  // ---------
  @Input() public selectable: boolean = false;
  @Input() public selectedBy: string = 'id';
  @Input() public selectedElements: any[] = [];
  @Output() selectedElementsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  // Pagination
  // ----------
  @Input() public pageable: any = true;

  // Sorting
  // -------
  @Input() public sortable: any = {
    allowUnsort: true,
    mode: 'multiple',
  };

  // Filtering
  // ---------
  @Input() public filterable: any = 'menu';

  // State persisting
  // ----------------
  @Input() public localStorageKey: string | null = null;

  constructor(
    protected toolbarService: ToolbarService,
    private notificationService: AppNotificationService,
    private httpClient: HttpClient,
    private router: Router,
    public statePersistingService: StatePersistingService
  ) { }
  ngAfterViewInit(): void {
    const savedState: any = this.localStorageKey ? this.statePersistingService.get(this.localStorageKey) : null;

    if (savedState) {
      this.state = savedState.state;
      this.toolbar.onSearchFieldChanged(savedState.searchValue);
    } else {
      this.state.filter = this.baseFilters;
      this.state.sort = this.baseSorts;
    }
  }

  ngOnDestroy(): void {
    if (this.localStorageKey) {
      this.statePersistingService.set(this.localStorageKey, { state: this.state, searchValue: this.searchValue });
    }
  }

  ngOnInit(): void {
    this.baseFilters = this.baseFilters ?? { logic: 'and', filters: [] };
    this.baseSorts = this.baseSorts ?? undefined;
  }

  // Add
  // ---
  public onAddButtonClicked() {
    if (this.add.observed) {
      this.add.emit(this);
    } else {
      this.router!.navigateByUrl(this.redirectUrl + '/new');
    }
  }

  public onConfirmDelete(dataItem: any): void {
    if (this.delete.observed) {
      this.delete.emit(dataItem);
    } else {
      if (this.service && this.service.url != '')
        this.service.delete(dataItem.id)
          .then((r: any) => { })
          .finally(() => {
            this.deleteModal.toggle();
            this.updateGridDatas();
          });
    }
  }

  public onDeleteButtonClicked(dataItem?: any) {
    this.deleteModal.data = dataItem;
    this.deleteModal.toggle();
  }

  onEditButtonClicked(dataItem: any) {
    if (this.edit.observed) {
      this.edit.emit(dataItem);
    } else {
      this.router.navigateByUrl(this.redirectUrl + '/' + dataItem.id);
    }
  }

  public dataStateChange(eventState: DataStateChangeEvent): void {
    // Get keys where this.colonne.queries != undefined / null
    // Get filter where field in keys
    let queryDictionnary = this.columns
      .filter((column) => column.queries != undefined)
      .map((column) => ({ key: column.field, query: column.queries }));

    // Set filter.queries =
    queryDictionnary.forEach((entry) => {
      this.addQueryToFilter(entry.key, eventState.filter, entry.query);
    });
    this.state = eventState;
    this.sortChange(eventState.sort as any[]);
    this.updateGridDatas();
  }

  /**
   * Recursively add query to filter object
   *
   * @param key - Field whose add the queries
   * @param filter - Kendo filter object to travel
   * @param queries - Array of queries to add to the field
   */
  private addQueryToFilter(key: string, filter: any, queries: any[]) {
    if (!filter) {
      filter = {};
    }
    if (filter.field == key) {
      filter.queries = queries;
    }
    if (filter.filters != undefined) {
      filter.filters.map((next: any) =>
        this.addQueryToFilter(key, next, queries)
      );
    }
    return filter;
  }

  public sortChange(sort: any[]): void {
    let finalSort = sort.filter(s => s.dir != undefined);
    let joinDictionary = this.columns
      .filter((column) => column.joins != undefined)
      .map((column) => ({ key: column.field, joins: column.joins }));

    finalSort = finalSort.map(sort => {
      sort.joins = joinDictionary.find(s => s.key == sort.field)?.joins;
      return sort;
    });
    this.state.sort = finalSort;
  }

  public updateGridDatas(): void {
    this.loading = true;
    if (this.service && this.service.url != '') {

      // used api route list
      let queryPromise = this.usedApiRouteList ? this.service.get(this.state, `${this.service.url}?list=1`) : this.service.get(this.state);

      queryPromise
        .then((r) => {
          // Apply frontend specific filter
          this.columns.forEach(column => {
            if (!column.frontendFilter) return;

            r.data.forEach((dataItem: any) => {
              column.frontendFilter(dataItem);
            })
          })

          this.dataResult = {
            data: r.data,
            total: r.total,
          };
          this.loading = false;
        })
        .catch((e: any) => {
          this.loading = false;
        });
    } else if (this.data) {
      this.dataResult = {
        data: this.data,
        total: this.data?.length,
      };
      this.loading = false;
    }
  }

  public search(inputValue: string | null): void {
    this.searchValue = inputValue || '';
    this.state.skip = 0;
    let columnFilters: CompositeFilterDescriptor = JSON.parse(JSON.stringify(this.baseFilters));

    const columnFilterLogic = 'or';
    let fieldFilters = this.columns.filter((c: any) => c.filter !== false).map((c: any) => {
      return {
        field: c.field,
        operator: 'contains',
        value: inputValue,
        ignoreCase: true,
        queries: c.queries ?? null,
      };
    });
    let columnFilter: CompositeFilterDescriptor = {
      logic: columnFilterLogic,
      filters: fieldFilters,
    };
    columnFilters.filters.push(columnFilter);

    if (inputValue?.length == 0) {
      this.state.filter = this.baseFilters;
    } else {
      this.state.filter = columnFilters;
    }

    this.updateGridDatas();
    this.loading = false;
  }

  // Export
  // ------
  public onExport(cb: ExportCallback) {
    if (this.export.observed) {
      this.export.emit();
      cb();
    } else {
      let exportState = Object.assign({}, this.state);
      delete exportState.skip;
      delete exportState.take;

      this.service?.export(exportState).pipe(finalize(cb)).subscribe();
    }
  }

  public addAction(action: any) {
    this.actions.push(action);
  }

  public fromDotNotation(dataItem: any, key: string) {
    if (dataItem) {
      return fromDotNotation(dataItem, key);
    }
    return null;
  }
}
