import { EventEmitter } from "@angular/core";

export class ArrowStepperItem {
    public active: boolean = false;
    public canHover: boolean = true;
    public bgColor: string = '#000';
    public notActiveBgColor: string = '#9b9b9b';
    public height: number = 40;
    public isFirst: boolean = false;
    public isLast: boolean = false;
    public onClick?: Function;
    public data?: any;

    constructor(item?: Partial<ArrowStepperItem>) {
        Object.assign(this, item);
    }
}

