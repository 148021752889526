import { Component, ViewChild } from '@angular/core';
import { MembersService } from './members.service';
import { PageComponent } from 'src/app/ui/page/page.component';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { CompaniesService } from '../companies/companies.service';
import { TenuresService } from '../companies/tenures/tenures.service';
import { CompanyRolesService } from '../companies/company-roles/company-roles.service';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { finalize, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent extends PageComponent {
  @ViewChild('grid') grid!: AppGridComponent;

  @ViewChild('historyModal') historyModal: ModalComponent = new ModalComponent();

  public membersHelperList: KendoComponentHelper = new KendoComponentHelper();

  public columns: any[] = [
    {
      field: 'person.lastname', title: 'Lastname', joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' }
      ]
    },
    {
      field: 'person.firstname', title: 'Firstname', joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' }
      ]
    },
    {
      field: 'person.primary_email', title: 'Email', joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' }
      ]
    },
    {
      field: 'person.person_tenure.displayName.fr', title: 'Tenure', filter: 'multi', service: this.tenuresService, joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' },
        { sequence: 2, tablename: 'person_tenures', foreignKey: 'people.tenure_id' }
      ]
    },
    {
      field: 'person.person_company.displayName.fr', title: 'Organization', filter: 'multi', service: this.companiesService, joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' },
        { sequence: 2, tablename: 'companies', foreignKey: 'people.company_id' }
      ]
    },
    {
      field: 'person.person_company_role.displayName.fr', title: 'Function', filter: 'multi', service: this.companyRolesService, joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' },
        { sequence: 2, tablename: 'company_roles', foreignKey: 'people.company_role_id' }
      ]
    },

  ];

  public membersSorts = [
    {
      dir: 'asc', field: 'person.lastname', joins: [
        { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' }
      ]
    }
  ]

  constructor(
    public service: MembersService,
    private tenuresService: TenuresService,
    private companyRolesService: CompanyRolesService,
    private companiesService: CompaniesService,
    private memberService: MembersService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.grid.updateGridDatas();
    this.grid.addAction({
      icon: 'plus',
      click: () => this.grid.onAddButtonClicked(),
    });
    this.grid.addAction({
      icon: 'file-export',
      click: () => this.historyModal.toggle(),
      atEnd: true
    });
    this.updateAvailableMembers();
  }

  private updateAvailableMembers() {
    forkJoin({
      members: this.memberService.getMembersList()
    }).subscribe((responses: any) => {
      const members = responses.members.data;
      this.membersHelperList.datas = this.membersHelperList.filteredDatas = members;
    });
  }

  public onConfirmExportHistory(e: any): void {
    this.service.exportMembershipHistory(e.member.id)
      .pipe(
        finalize(()=>{
          this.historyModal.toggle();
        })
      )
      .subscribe();
  }
}
