import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { MSALUser } from '../msal-user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authenticationService.loginEvent.subscribe((user: MSALUser) => {
      this.router.navigateByUrl('/admin');
    });
    this.authenticationService.connectedEvent.subscribe((user: MSALUser) => {
      this.router.navigateByUrl('/admin');
    });
  }
}
