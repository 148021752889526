import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToolbarService } from './toolbar.service';
import { ToolbarAction } from './ToolbarAction';



export type ExportCallback = () => void;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class AppToolbarComponent implements OnInit {

  // Elements
  // --------
  @Input() actions: ToolbarAction[] = [];
  @Input() align: string = 'between';
  @Input() searchValue: string | null = null;
  @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();


  // Options
  // -------
  @Input() searchable: boolean = false;
  @Input() filterable: boolean = false;
  @Input() exportable: boolean = false;
  @Input() isExporting: boolean = false;

  // Events
  // ------
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<ExportCallback> = new EventEmitter<ExportCallback>();

  constructor(
    public toolbarService: ToolbarService
  ) { }

  ngOnInit(): void {
  }

  onSearchFieldChanged(e: any) {
    this.searchValue = e;
    this.search.emit(e);
  }

  onFilterButtonClicked(e: any) {
    this.filter.emit(e);
  }

  onExportButtonClicked() {
    this.isExporting = true;
    this.export.emit(() =>{
      this.isExporting = false;
    });
  }
}
