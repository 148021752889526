import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArrowStepperItem } from './arrow-stepper-item.model';

@Component({
  selector: 'arrow-stepper-item',
  templateUrl: './arrow-stepper-item.component.html',
  styleUrls: ['./arrow-stepper-item.component.scss']
})
export class ArrowStepperItemComponent implements OnInit {
  public highlight: boolean = false;
  @Input() item: ArrowStepperItem = new ArrowStepperItem();

  ngOnInit(): void {
    this.highlight = this.item.active;
  }

  public onItemClick(item: ArrowStepperItem) {
    if (this.item.onClick) {
      this.item.onClick(item);
      return;
    }
  }
}
