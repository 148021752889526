import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.scss']
})
export class TranslationModalComponent implements OnInit {
  @Input() opened: boolean = false;
  @Input() title: string = "Translate";
  @Input() cancelText: any = "Cancel";
  @Input() confirmText: any = "Validate";
  @Input() data: any = {};

  @Output() confirm = new EventEmitter<any>();


  

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  onConfirmButtonClicked() {
    this.confirm.emit(this.data);
  }

  public toggle() {
    this.opened = !this.opened;
  }
}