<kendo-card class="address-card w-100">
  <div class="k-loading-panel" *ngIf="loading">
    <div class="k-loading-panel-mask"></div>
    <div class="k-loading-panel-wrapper">
      <kendo-loader
        type="infinite-spinner"
        themeColor="primary"
        size="large"
      >
      </kendo-loader>
      <div class="k-loading-panel-text">{{"Loading" | translate }}...</div>
    </div>
  </div>
  <kendo-card-header class="bg-light w-100">
    <div class="row">
      <h5 class="col-4">{{address.address_type.displayName.en | translate}}</h5>
      <div class="col-8">
        <div class="row">
          <div class="col-10">
            <app-google-places
              [name]="address.address_id"
              (onAddress)="onGoogleAddress($event)"
            ></app-google-places>
          </div>
          <div class="col-2">
            <button
              [hasPermission]="['members.update']"
              [toRemove]="true"
              class="btn btn-link ms-3"
              (click)="onDeleteButtonClicked()"
            ><i class="fas fa-trash"></i></button>
          </div>
        </div>
      </div>
    </div>
  </kendo-card-header>
  <kendo-card-body *ngIf="address.address.street">
    <div class="k-form">
      <div class="row">
        <div class="col-sm-8 d-flex flex-column">
          <kendo-label text="{{'Street' | translate}}"></kendo-label>
          <span>{{address.address.street}}</span>
        </div>
        <div class="col-sm-2 d-flex flex-column">
          <kendo-label text="{{'N°' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="number"
            [(ngModel)]="address.address.number"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="number"
            [ngModel]="address.address.number"
          ></kendo-textbox>
        </div>
        <div class="col-sm-2 d-flex flex-column">
          <kendo-label text="{{'Box' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="box"
            [(ngModel)]="address.address.box"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="box"
            [ngModel]="address.address.box"
          ></kendo-textbox>
        </div>
      </div>
      <div
        class="row mt-3"
      >
        <div class="col-sm-6 d-flex flex-column">
          <kendo-label text="{{'Postal code' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="parc"
            [(ngModel)]="address.address.postalCode"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="parc"
            [ngModel]="address.address.postalCode"
          ></kendo-textbox>
        </div>
        <div class="col-sm-6 d-flex flex-column">
          <kendo-label text="{{'City' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="building"
            [(ngModel)]="address.address.localityName"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="building"
            [ngModel]="address.address.localityName"
          ></kendo-textbox>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6 d-flex flex-column">
          <kendo-label text="{{'Parc' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="parc"
            [(ngModel)]="address.address.parc"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="parc"
            [ngModel]="address.address.parc"
          ></kendo-textbox>
        </div>
        <div class="col-sm-6 d-flex flex-column">
          <kendo-label text="{{'Building' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="false"
            name="building"
            [(ngModel)]="address.address.building"
          ></kendo-textbox>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [isFallback]="true"
            [disabled]="true"
            name="building"
            [ngModel]="address.address.building"
          ></kendo-textbox>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6 d-flex flex-column">
          <kendo-label text="{{'Country' | translate}}"></kendo-label>
          <kendo-textbox
            [hasPermission]="['members.update']"
            [disabled]="true"
            name="country"
            [ngModel]="address.address?.country?.displayName?.fr"
          ></kendo-textbox>
        </div>
      </div>
    </div>
  </kendo-card-body>
</kendo-card>
