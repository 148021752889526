import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GooglePlaceAddress } from './GooglePlaceAddress.model';

@Component({
  selector: 'app-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements AfterViewInit {
  @ViewChild('googlePlacesAutocomplete') public googlePlacesAutocomplete!: any;

  @Input() name: string = "";

  @Output() onAddress: EventEmitter<GooglePlaceAddress> = new EventEmitter<GooglePlaceAddress>();

  constructor() { }

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.googlePlacesAutocomplete.input.nativeElement,
      {
        types: ['geocode'],
        // componentRestrictions: { country: 'BE' } 
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.onPlaceChanged(autocomplete.getPlace());
    });
  }

  private onPlaceChanged(place: any) {
    const address: GooglePlaceAddress = new GooglePlaceAddress();

    place.address_components?.forEach((component: any) => {
      const data = { long_name: component.long_name, short_name: component.short_name };

      switch (component.types[0]) {
        case "street_number":
          address.number = data;
          break;
        case "route":
          address.street = data;
          break;
        case "locality":
          address.locality = data;
          break;
        case "administrative_area_level_2":
          address.adminArea2 = data;
          break;
        case "administrative_area_level_1":
          address.adminArea1 = data;
          break;
        case "country":
          address.country = data;
          break;
        case "postal_code":
          address.postalCode = data;
          break;

        default:
          break;
      }
    });

    this.onAddress.emit(address);
  }
}
