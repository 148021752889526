import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { TranslateLoader, TranslateModuleConfig } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxUiLoaderConfig, PB_DIRECTION, POSITION, SPINNER } from "ngx-ui-loader";
import { environment } from "src/environments/environment";

const mProtectedResourceMap = new Map([
    [
        environment.mGraphEndoint + '/me',
        [
            'user.read',
        ]
    ],
    [
        environment.backendBaseUrl,
        ['User.Read']
    ]
]);
const mGuardPermissions = ['user.read'];

export class AppConfig {

    // Translations
    // ------------
    public static translationConfig:TranslateModuleConfig = {
        defaultLanguage: 'fr',
        loader: {
            provide: TranslateLoader,
            useFactory: AppConfig.HttpLoaderFactory,
            deps: [HttpClient]
        }
    };

    static HttpLoaderFactory(http: HttpClient) {
        return new TranslateHttpLoader(http, environment.backendBaseUrl + 'base/translations/front/', '');
    }


    // Azure AAD authentication
    // ------------------------
    public static mPublicApplicationConfig = {
        auth: {
            clientId: environment.mClientID, // Application (client) ID from the app registration
            authority: environment.mAuthority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
            redirectUri: environment.frontendBaseURL// This is your redirect URI
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: environment.isIE, // Set to true for Internet Explorer 11
        },
        system: {
            loggerOptions: {
                loggerCallback: () => { },
                piiLoggingEnabled: false
            }
        }
    };

    public static mGuarConfig: MsalGuardConfiguration = {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
            scopes: mGuardPermissions
        },
        loginFailedRoute: "/login"
    };

    public static mInterceptorConfiguration: MsalInterceptorConfiguration = {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: mProtectedResourceMap
    }

    public static httpInterceptorProviderConfig = {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    };

    // Loader
    // ------
    public static ngxUiLoaderConfig: NgxUiLoaderConfig = {
        bgsColor: 'white',
        bgsPosition: POSITION.bottomLeft,
        bgsSize: 40,
        bgsType: SPINNER.rectangleBounce,
        pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
        pbThickness: 5, // progress bar thickness
    };
}
