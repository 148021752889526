import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Directive({ selector: '[hasPermission]' })
export class UserPermissionDirective implements OnInit {
  private permissions!: string[];

  constructor(
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @Input('hasPermission') set hasPermission(permissions: string[]) {
    this.permissions = permissions;
  }

  @Input() isFallback: boolean | undefined = undefined;
  @Input() toRemove: boolean = false;

  ngOnInit() {
    // If user has permission
    if (this.authenticationService.hasOnePermission(this.permissions)) {
      if (this.isFallback === true) {
        this.elementRef.nativeElement.remove();
      }

      return;
    }

    if (this.isFallback === false) {
      this.elementRef.nativeElement.remove();
    } else {
      if (this.toRemove) {
        this.elementRef.nativeElement.remove();
      } else {
        // this.disableElement(this.elementRef.nativeElement);
      }
    }
  }

  private disableElement(element: any) {
    // this.renderer.setAttribute(element, "disabled", "true");

    // if (element.children) {
    //   for (const elem of element.children) {
    //     this.disableElement(elem);
    //   }
    // }
  }
}