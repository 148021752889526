<div
    class="arrow-stepper"
    [class.active]="highlight"
    [class.canHover]="item.canHover"
    (click)="onItemClick(item)"
    (mouseenter)="highlight = item.canHover ? true : highlight"
    (mouseleave)="highlight = item.canHover && !item.active ? false : item.active"
>
    <div
        *ngIf="!item.isFirst"
        class="arrow-stepper-start"
        [style.borderWidth]="(item.height / 2) + 'px'"
        [style.borderTopColor]="highlight ? item.bgColor : item.notActiveBgColor"
        [style.borderBottomColor]="highlight ? item.bgColor : item.notActiveBgColor"
        [style.marginRight]="'-' + (item.height / 2) + 'px'"
    ></div>
    <div
        class="arrow-stepper-content"
        [style.backgroundColor]="highlight ? item.bgColor : item.notActiveBgColor"
        [style.height]="item.height + 'px'"
    >
        <ng-content></ng-content>
    </div>
    <div
        *ngIf="!item.isLast"
        class="arrow-stepper-end"
        [style.borderWidth]="(item.height / 2) + 'px'"
        [style.borderLeftColor]="highlight ? item.bgColor : item.notActiveBgColor"
        [style.marginRight]="'-' + (item.height / 2) + 'px'"
    ></div>
</div>