import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { AppointmentService } from './appointment.service';
import { Router } from '@angular/router';
import { OptionChangedEvent } from 'devextreme/ui/scheduler';
import { DxSchedulerComponent } from 'devextreme-angular';
import { Appointment } from './appointment';
import { AppointmentStatus, AppointmentStatusBackgroundColor, AppointmentStatusCode, AppointmentStatusColor } from './appointment-statuses/appointment-status';
import { StatusCellData } from 'src/app/ui/grid/cells/status-cell/status-cell.component';
import { ToolbarAction } from 'src/app/ui/toolbar/ToolbarAction';
import { AppNotificationService } from 'src/app/app-notification.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent extends PageComponent implements AfterViewInit {
  @ViewChild('grid') grid!: AppGridComponent;
  public columns: any[] = [
    {
      field: 'startDate', title: 'Date', width: 120, type: 'date', filter: 'date', data: {
        format: (dataItem: Appointment): string => {
          if (!dataItem.startDate) return '';
          const date = new Date(dataItem.startDate);
          return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        }
      }
    },
    {
      field: 'startDate', title: 'Start at', width: 120, type: 'date', filter: 'date', data: {
        format: (dataItem: Appointment): string => {
          if (!dataItem.startDate) return '';
          const date = new Date(dataItem.startDate);
          return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
        }
      }
    },
    {
      field: 'endDate', title: 'End at', width: 120, type: 'date', filter: 'date', data: {
        format: (dataItem: Appointment): string => {
          if (!dataItem.endDate) return '';
          const date = new Date(dataItem.endDate);
          return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
        }
      }
    },
    { field: 'subject', title: 'Subject' },
    {
      field: 'appointment_status.displayName.fr', title: 'Status', joins: [{
        sequence: 1, tablename: "appointment_statuses", foreignKey: "Appointment.appointment_status_id"
      }], width: 200,
      type: 'status',
      data: new StatusCellData({
        colorFunction: this.getAppointmentStatusColor,
        backgroundColorFunction: this.getAppointmentStatusBgColor
      })
    },
    {
      property:'appointment_committees', field: 'appointment_committees.committee.displayName.fr', title: 'Committees', type: 'appointment_committees', width: 220
    },
    { field: 'promoter', title: 'Promoter', width: 250 },
  ];
  public baseSorts = [];

  public opened: boolean = false;
  public startDate = new Date();
  public currentDate = new Date();
  protected schedulerViews = ['day','week','month'];
  public appointments = [];

  public toolbarActions = [
    new ToolbarAction({ code: 'import_appointment', text: 'Import an appointment', icon: 'plus', click: () => this.onImportAppointmentButtonClicked(), btnStyle: 'outline-primary' })
  ];

  @ViewChild('scheduler') private scheduler?: DxSchedulerComponent;

  constructor(
    public service: AppointmentService,
    public router: Router,
    public appNotificationService: AppNotificationService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.currentDate =  new Date();
    this.grid?.updateGridDatas();
  }

  public async onImportAppointmentButtonClicked() {
    this.opened = true;
    await this.fetchAppointments();
    this.scheduler?.instance.scrollTo(new Date());
  }

  public onOptionChanged(e: OptionChangedEvent): void {
    if (e.name === 'currentDate') {
      this.currentDate = e.value;
      this.fetchAppointments(this.getLastMonday(e.value));
    }
  }

  public async fetchAppointments(startDate = this.getLastMonday()) {
    this.appNotificationService.toggleLoader();

    const appointments = await this.service.getUserAppointments(startDate.toUTCString());

    this.appointments = appointments.reduce((all: any, a: any) => {
      return [...all, {
        text: a.subject,
        startDate: a.start.dateTime + 'Z',
        endDate: a.end.dateTime + 'Z',
        iCalUId: a.iCalUId
      }]
    }, []);

    this.appNotificationService.toggleLoader();
  }

  private getLastMonday(date = new Date()) {
    const today = new Date(date);
    const dayOfWeek = today.getDay();
    const daysUntilLastMonday = (dayOfWeek + 6) % 7;
    const lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - daysUntilLastMonday);
    lastMonday.setHours(0);
    lastMonday.setMinutes(0);
    lastMonday.setSeconds(0);
    lastMonday.setMilliseconds(0);
    return lastMonday;
  }

  public async onConfirmImportAppointment(e: any) {
    e.cancel = true;
    this.appNotificationService.toggleLoader();
    await this.service.importAppointments(e.appointmentData.iCalUId)
      .catch((_ : HttpErrorResponse) => {
        return;
      })
      .then(_ => {
        this.appNotificationService.toggleLoader();
        this.opened = false;
        this.grid.updateGridDatas();
      });

  }

  public onEdit(appointment: any): void {
    this.router.navigateByUrl('/admin/appointments/' + appointment.id);
  }

  public getAppointmentStatusColor(dataItem: Appointment) {
    switch (dataItem?.appointment_status?.code) {
      case AppointmentStatusCode.New: return AppointmentStatusColor.New;
      case AppointmentStatusCode.Convene: return AppointmentStatusColor.Convene;
      case AppointmentStatusCode['In progress']: return AppointmentStatusColor['In progress'];
      case AppointmentStatusCode.Completed: return AppointmentStatusColor.Completed;
      default: return AppointmentStatusColor.Default;
    }
  }

  public getAppointmentStatusBgColor(dataItem: Appointment) {
    switch (dataItem?.appointment_status?.code) {
      case AppointmentStatusCode.New: return AppointmentStatusBackgroundColor.New;
      case AppointmentStatusCode.Convene: return AppointmentStatusBackgroundColor.Convene;
      case AppointmentStatusCode['In progress']: return AppointmentStatusBackgroundColor['In progress'];
      case AppointmentStatusCode.Completed: return AppointmentStatusBackgroundColor.Completed;
      default: return AppointmentStatusBackgroundColor.Default;
    }
  }
}
