import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { CompaniesService } from '../../companies/companies.service';
import { CompanyRolesService } from '../../companies/company-roles/company-roles.service';
import { CompanyRoleTypesService } from '../../companies/company-roles/company_role_types.service';
import { CompanyTypesService } from '../../companies/company_types.service';
import { TenuresService } from '../../companies/tenures/tenures.service';
import { GendersService } from '../genders.service';
import { TitlesService } from '../titles.service';
import { PersonService } from '../person.service';
import { Member } from '../../members/member';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent implements OnInit {
  @Input() public person?: any;
  @Output() public personChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public member?: Member;
  @Output() public memberChange: EventEmitter<Member> = new EventEmitter<Member>();

  public titlesHelper: KendoComponentHelper = new KendoComponentHelper();
  public gendersHelper: KendoComponentHelper = new KendoComponentHelper();
  public tenureHelper: KendoComponentHelper = new KendoComponentHelper();
  public companyHelper: KendoComponentHelper = new KendoComponentHelper();
  public companyTypesHelper: KendoComponentHelper = new KendoComponentHelper();
  public companyRolesHelper: KendoComponentHelper = new KendoComponentHelper();
  public companyRoleTypesHelper: KendoComponentHelper = new KendoComponentHelper();

  constructor(
    public personService: PersonService,
    private titlesService: TitlesService,
    private gendersService: GendersService,
    private tenuresService: TenuresService,
    private companiesService: CompaniesService,
    private companyRolesService: CompanyRolesService,
    private companyTypesService: CompanyTypesService,
    private companyRoleTypesService: CompanyRoleTypesService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    forkJoin({
      titles: this.titlesService.get(),
      genders: this.gendersService.get(),
      tenures: this.tenuresService.all({ sort: [{ dir: "asc", field: "displayName.fr" }] }),
      companies: this.companiesService.all({ sort: [{ dir: "asc", field: "displayName.fr" }] }),
      companyTypes: this.companyTypesService.all({ sort: [{ dir: "asc", field: "displayName.fr" }] }),
      companyRoles: this.companyRolesService.all({ sort: [{ dir: "asc", field: "displayName.fr" }] }),
      companyRoleTypes: this.companyRoleTypesService.all({ sort: [{ dir: "asc", field: "displayName.fr" }] })
    }).subscribe(({ titles, genders, tenures, companies, companyTypes, companyRoles, companyRoleTypes }: any) => {
      this.titlesHelper.datas = titles.data;
      this.gendersHelper.datas = genders.data;
      this.tenureHelper.datas = this.tenureHelper.filteredDatas = tenures.data;
      this.companyHelper.datas = this.companyHelper.filteredDatas = companies.data;
      this.companyTypesHelper.datas = this.companyTypesHelper.filteredDatas = companyTypes.data;
      this.companyRolesHelper.datas = this.companyRolesHelper.filteredDatas = companyRoles.data;
      this.companyRoleTypesHelper.datas = this.companyRoleTypesHelper.filteredDatas = companyRoleTypes.data;
    })
  }

  // Combobox
  // --------
  public normalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        return {
          id: '-1',
          code: content,
          displayName: {
            'fr': content,
            'en': content
          }
        }
      })
    );

  public companyNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        return {
          id: '-1',
          language_id: 1,
          code: content,
          displayName: {
            'fr': content,
            'en': content
          }
        }
      })
    );

  public getDatePickerValue(date: any): Date {
    return new Date(date);
  }
  public setEntryDateValue(date: Date): void {
    if (!this.member) return;
    this.member.entry_date = date.getFullYear().toString().padStart(4, '0') + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    return;
  }
}