import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppNotificationService } from './app-notification.service';
import { AppTranslationsService } from './app-translations.service';
import { AuthenticationService } from './auth/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private authenticationService: AuthenticationService,
    public notificationService: AppNotificationService,
    private translationService: AppTranslationsService
  ) { }

  ngOnInit() {
    this.authenticationService.registerForAuthenticationEvent();
    this.translationService.init();
  }

  ngOnDestroy(): void {
    this.authenticationService.unregisterForAuthenticationEvent();
  }
}
