import { Component } from '@angular/core';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent extends BaseCell {
  override ondataItemChange() {
    this.value = this.data.format(this.dataItem);
  }
}
