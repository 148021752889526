import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  public actions: any[] = [];

  public searchable: boolean = false;
  public search = (e: any) => { };
  public searchValue: string | null = null;
  public filterable: boolean = false;
  public filter = (e: any) => { };
  public exportable: boolean = false;
  public export = (e: any) => { };

  constructor() {

  }

  reset() {
    this.actions = [];
    this.searchable = false;
    this.filterable = false;
    this.exportable = false;
    this.searchValue = null;
    this.search = (e: any) => { };
    this.filter = (e: any) => { };
    this.export = (e: any) => { };
  }


  addAction(rootItem: any) {
    if (!this.actions.find(((e: any) => e.code == rootItem.code))) {
      this.actions.push(rootItem);
    }
  }
  removeAction(rootItem: any) {
    if (!this.actions.find(((e: any) => e.code == rootItem.code))) {
      this.actions.push(rootItem);
    }
  }
}
