import { Directive } from '@angular/core';

@Directive({
  selector: '[modalContent]'
})
export class ModalContentDirective {

  constructor() { }

}
