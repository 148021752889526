import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  @Input() opened: boolean = false;
  @Input() title: string = "Warning";
  @Input() content: string = "Do you want to continue?";
  @Input() cancelText: any = "Cancel";
  @Input() confirmText: any = "Validate";
  @Input() data: any;

  @Output() confirm = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onConfirmButtonClicked() {
    this.confirm.emit(this.data);
  }

  public toggle() {
    this.opened = !this.opened;
  }
}
