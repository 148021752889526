import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsCommitteesService extends AppApiService {
  public url: string | null = 'cesew/appointment_committees';


  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
  ) {
    super(http, notificationService);
  }
}
