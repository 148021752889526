<kendo-card
    *ngIf="element"
    class="w-100 rounded-0 mt-3"
>
    <kendo-card-body>
        <div class="k-form my-4">
            <div class="row">
                <div class="col-sm-3">
                    <kendo-label text="{{'Name' | translate}}"></kendo-label>
                    <kendo-textbox
                        [disabled]="true"
                        name="lastname"
                        [(ngModel)]="element?.displayName.fr"
                    ></kendo-textbox>
                    <!-- <kendo-textbox [hasPermission]="['members.update']" [isFallback]="false" name="lastname" [(ngModel)]="element?.displayName.fr"></kendo-textbox>
                    <kendo-textbox [hasPermission]="['members.update']" [isFallback]="true" [disabled]="true" name="lastname" [(ngModel)]="element?.displayName.fr"></kendo-textbox> -->
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-2">
                <div [ngSwitch]="element.type">
                    <div *ngSwitchCase="'instance'">
                        <user-type-badge text="Committee"></user-type-badge>
                    </div>
                    <div *ngSwitchCase="'section'">
                        <user-type-badge
                            text="Section"
                            background="white"
                            color="primary"
                            border="primary"
                        ></user-type-badge>
                    </div>
                </div>

            </div>
            <div class="col"></div>
            <div
                class="col-2"
                *ngIf="element.microsoft_id"
            >
                <user-type-badge
                    text="Managed"
                    background="white"
                    color="success"
                    border="success"
                ></user-type-badge>
            </div>
        </div>
        <kendo-tabstrip [keepTabContent]="true">
            <kendo-tabstrip-tab
                title="{{ 'Members' | translate }}"
                [selected]="true"
            >
                <ng-template kendoTabContent>
                    <app-grid
                        #committeeMembersGrid
                        [actions]="committeeMembersGridActions"
                        [exportable]="true"
                        [columns]="committeeMembersColumns"
                        [service]="committeesMembersService"
                        (delete)="onDeleteCommitteeMemberBtn($event)"
                        (edit)="onCommitteeMemberEditBtn($event)"
                        (doubleClick)="onCommitteeMemberDoubleClick($event)"
                        [searchable]="true"
                        [baseSorts]="committeeMembersSorts"
                        [baseFilters]="committeeMembersFilters"
                    ></app-grid>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab
                *ngIf="element.type === 'instance'"
                title="{{ 'Sections' | translate }}"
            >
                <ng-template kendoTabContent>
                    <app-grid
                        #committeeSectionGrid
                        [deletable]="false"
                        [actions]="committeeSectionsGridActions"
                        [columns]="committeeSectionsColumns"
                        [service]="committeesService"
                        (edit)="onEditCommitteeSectionBtnClicked($event)"
                        (doubleClick)="onCommitteeSectionDoubleClick($event)"
                        [searchable]="true"
                        [baseSorts]="committeeSectionsSorts"
                        [baseFilters]="committeeSectionsFilters"
                    ></app-grid>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>

    </kendo-card-body>
</kendo-card>

<!-- Committee members -->
<app-modal
    #addCommitteeMemberModal
    title="AssignMember"
    (confirm)="onConfirmAddCommitteeMemberBtn()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Member' | translate}}"></kendo-label>
        <kendo-dropdownlist
            [loading]="membersHelper.filteredDatas.length === 0"
            class="mb-3"
            [data]="membersHelper.filteredDatas"
            [filterable]="true"
            [(ngModel)]="addCommitteeMemberModal.data.member"
            textField="person.fullname"
            (filterChange)="membersHelper.handleFilter('person.fullname', $event)"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'CommitteeRole' | translate}}"></kendo-label>
        <kendo-dropdownlist
            [data]="committeeRolesHelper.filteredDatas"
            [filterable]="true"
            [(ngModel)]="addCommitteeMemberModal.data.committee_role"
            textField="displayName.fr"
            (filterChange)="committeeRolesHelper.handleFilter('displayName.fr', $event)"
            valueField="id"
        ></kendo-dropdownlist>

        <kendo-label text="{{'Date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="addCommitteeMemberModal.data.assignment_date"></kendo-datepicker>

    </ng-template>
</app-modal>

<app-modal
    #editCommitteeMemberModal
    title="Assignation"
    (confirm)="onComfirmCommitteeMemberEditBtn()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Fullname' | translate}}"></kendo-label>
        <kendo-textbox
            class="mb-3"
            name="fullname"
            [(ngModel)]="editCommitteeMemberModal?.data.member.person.fullname"
            [disabled]="true"
        ></kendo-textbox>

        <kendo-label text="{{'CommitteeRole' | translate}}"></kendo-label>
        <kendo-dropdownlist
            [data]="committeeRolesHelper.filteredDatas"
            [filterable]="true"
            [(ngModel)]="editCommitteeMemberModal.data.committee_role"
            textField="displayName.fr"
            (filterChange)="committeeRolesHelper.handleFilter('displayName.fr', $event)"
            valueField="id"
        ></kendo-dropdownlist>

    </ng-template>
</app-modal>

<!-- Committee sections -->
<app-modal
    #addCommitteeSectionModal
    title="Add section"
    (confirm)="onCommitteeSectionAddBtn()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Name' | translate}}"></kendo-label>
        <kendo-textbox
            name="name"
            [(ngModel)]="addCommitteeSectionModal?.data.displayName.fr"
        ></kendo-textbox>
    </ng-template>
</app-modal>

<app-modal
    #historyCommitteeMemberModal
    width="1500px"
    title="History of people who have been members of the body"
    [showDialogActions]="false"
>
    <ng-template modalContent>
       <app-history-committees-members-grid [committees_id]="element.id"></app-history-committees-members-grid>
    </ng-template>
</app-modal>

<app-committees-appointments-statistics-modal #committeeAppointmentsStatisticsModal  [committees_id]="element.id"></app-committees-appointments-statistics-modal>