<app-grid
    #historyCommitteeMembersGrid
    [exportable]="false"
    [deletable]="false"
    [columns]="historyCommitteeMembersColumns"
    [service]="historyCommitteesMembersService"
    [searchable]="true"
    [baseSorts]="historyCommitteeMembersSorts"
    [baseFilters]="historyCommitteeMembersFilters"
    (edit)="onHistoryCommitteeMemberEditBtn($event)"
    ></app-grid>
    <!-- (doubleClick)="onCommitteeMemberDoubleClick($event)" -->

<app-modal
    #editCommitteeMemberModal
    title="Assignation"
    (confirm)="onComfirmCommitteeMemberEditBtn()"
>
    <ng-template modalContent>
        <kendo-label text="{{'Fullname' | translate}}"></kendo-label>
        <kendo-textbox
            class="mb-3"
            name="fullname"
            [(ngModel)]="editCommitteeMemberModal?.data.member.person.fullname"
            [disabled]="true"
        ></kendo-textbox>

        <kendo-label text="{{'CommitteeRole' | translate}}"></kendo-label>
        <kendo-textbox
            class="mb-3"
            name="fullname"
            [(ngModel)]="editCommitteeMemberModal?.data.committee_role.displayName.fr"
            [disabled]="true"
        ></kendo-textbox>
       
        <kendo-label text="{{'Assignment date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="editCommitteeMemberModal.data.assignment_date"></kendo-datepicker>

        <kendo-label text="{{'Assignment end date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="editCommitteeMemberModal.data.deleted_at"></kendo-datepicker>

    </ng-template>
</app-modal>