import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { State } from '@progress/kendo-data-query';
import { AppApiService } from './app-api.service';
import { AppNotificationService } from './app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationsService extends AppApiService {
  public loading: boolean = false;
  public url: string | null = 'base/translations';
  public currentLanguage: string = 'fr';
  constructor(
    protected http: HttpClient,
    private translateService: TranslateService,
    protected notificationService?: AppNotificationService,
  ) {
    super(http, notificationService);
    this.init();
  }

  init() {
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('fr');

    const browserLang = this.translateService.getBrowserLang() ?? 'fr';
    this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

    this.currentLanguage = this.translateService.currentLang;
  }

  change(e: any) {
    this.translateService.use(e);
    this.currentLanguage = e;
  }
}
