<kendo-dialog title="{{title | translate }}" *ngIf="opened" (close)="opened=!opened" [minWidth]="250" [width]="500">
    <div class="py-4">
        <div class="row d-flex align-items-middle" *ngFor="let locale of translateService.getLangs()">
            <label class="col-sm-2 form-control form-label border-0 m-0">{{locale | translate | uppercase}}</label>
            <input class="col-sm-10 " kendoTextBox name="data[locale]" [(ngModel)]="data[locale]">
        </div>
    </div>

    <kendo-dialog-actions>
        <button kendoButton (click)="opened=!opened" translate>{{cancelText}}</button>
        <button kendoButton (click)="onConfirmButtonClicked()" themeColor="primary" translate> {{confirmText}}</button>
    </kendo-dialog-actions>
</kendo-dialog>