<app-toolbar [actions]="actions"></app-toolbar>
<div class="row my-3" *ngIf="sendLetterToPrivate">
    <div class="col-sm-3">
        <kendo-label text="{{'Send letter to private' | translate}}"></kendo-label>
        <kendo-switch [disabled]="!authenticationService.hasOnePermission(['members.update'])" class="ms-3" [(ngModel)]="sendLetterToPrivate"></kendo-switch>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 my-2" *ngFor="let address of addresses; let i=index;">
        <app-address [(address)]="addresses[i]" (delete)="onConfirmDelete($event)"></app-address>
    </div>
</div> 

<app-modal #addModal title="Add address" (confirm)="onConfirmAdd()">
    <ng-template modalContent>
        <kendo-label text="{{'Address type' | translate}}"></kendo-label>
        <kendo-dropdownlist [data]="addressTypesHelper.filteredDatas" name="addressType" [(ngModel)]="addModal.data.address_type" textField="displayName.fr" valueField="id"></kendo-dropdownlist>
    </ng-template> 
</app-modal>