export class GooglePlaceAddress {
    street !: GooglePlacedata;
    number?: GooglePlacedata;
    locality?: GooglePlacedata;
    adminArea2?: GooglePlacedata;
    adminArea1?: GooglePlacedata;
    country?: GooglePlacedata;
    postalCode?: GooglePlacedata;
}

class GooglePlacedata {
    long_name!: string;
    short_name!: string;
}