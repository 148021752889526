import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { CompaniesService } from '../companies/companies.service';
import { CompanyRolesService } from '../companies/company-roles/company-roles.service';
import { CompanyRoleTypesService } from '../companies/company-roles/company_role_types.service';
import { CompanyTypesService } from '../companies/company_types.service';
import { PersonService } from './person.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent extends PageComponent {
  @ViewChild('grid') grid!: AppGridComponent;
  public columns: any[] = [
    { field: 'lastname', title: 'Lastname' },
    { field: 'firstname', title: 'Firstname' },
    {
      field: 'person_company_type.displayName.fr', title: 'Organisation type', filter: 'multi', service: this.companyTypesService, joins: [{
        sequence: 2, tablename: "company_types", foreignKey: "people.person_company_type_id"
      }]
    },
    {
      field: 'person_company.displayName.fr', title: 'Organization', filter: 'multi', service: this.companiesService, joins: [{
        sequence: 2, tablename: "companies", foreignKey: "people.company_id"
      }]
    },
    {
      field: 'person_company_role_type.displayName.fr', title: 'Function type', filter: 'multi', service: this.companyRoleTypesService, joins: [{
        sequence: 2, tablename: "company_role_types", foreignKey: "people.person_company_role_type_id"
      }]
    },
    {
      field: 'person_company_role.displayName.fr', title: 'Function', filter: 'multi', service: this.companyRolesService, joins: [{
        sequence: 2, tablename: "company_roles", foreignKey: "people.company_role_id"
      }]
    },
    { field: 'newsletter_revue_wallonie', title: 'Destinataires revue', filter: 'boolean' },
    { field: 'newsletter_externes', title: 'Destinataires Newsletter', filter: 'boolean' },
    { field: 'invite_for_cese_activities', title: 'Invités aux activités', filter: 'boolean' },
  ];

  public baseSorts = [{ dir: 'asc', field: 'lastname' }];

  constructor(
    public service: PersonService,
    private companyRolesService: CompanyRolesService,
    private companiesService: CompaniesService,
    private companyRoleTypesService: CompanyRoleTypesService,
    private companyTypesService: CompanyTypesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.grid.updateGridDatas();
    this.grid.addAction({
      icon: 'plus',
      click: () => this.grid.onAddButtonClicked(),
    });
  }

  public onEdit(person: any): void {
    if (person.isMember) {
      this.router.navigateByUrl('/admin/members/' + person.isMember);
    } else {
      this.router.navigate([person.id], { relativeTo: this.activatedRoute });
    }
  }
}