export class Person {
    id!: string;
    firstname: string | null = null;
    lastname: string | null = null;
    title_id: number | undefined;
    gender_id: number | undefined;
    persons_contacts: any;
    persons_addresses: any;
    person_title: any;
    microsoft_id?: string;
    primary_email?: string;
    secondary_email?: string;

    newsletter_revue_wallonie?: string;
    newsletter_revue_wallonie_count?: number;
    newsletter_externes?: string;
    invite_for_cese_activities?: string;
    
    legal_mention: boolean = false;
    send_mail_to_private_address: boolean = false;
}
